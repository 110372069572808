import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js';

import materials from '../../constants/galleriumMaterials';

const MobileGalleriumGallery = ({ currentUser }) => {
  // useEffect(() => {
  //   ReactGA.pageview(`/gallerium/virtual-gallery`)
  //   amplitude.getInstance().logEvent(`Visit Gallerium-virtual-gallery`);
  // }, [currentUser.id])

  return (
    <div>
      <Page>
        <div className='outer-wrapper'>

          <div className='container'>
            
            <div className='title-container'>
              <p className='title'>Virtual Gallery</p>
              <Link className='back-button' to='/gallerium'>Back</Link>
            </div>

            {
              materials[3].materials.map((item, index) => (
                <a href={item.link} className='item-container' target='_blank' rel='noopener noreferrer' key={index}>
                  <img src={item.image} className='item-image'/>
                  <div className='item-banner'>
                    <p>{item.name}</p>
                  </div>
                </a>
              ))
            }

          </div>

        </div>
      </Page>
    </div>
  );
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 30px 0px 10px 0px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    width: 80%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-top: 10px;
  }

  .item-container {
    width: 100%;
    height: auto;
    position: relative;
  }

  .item-image {
    width: 100%;
    margin-bottom: 20px;
  }

  .item-banner {
    width: 100%;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: transformY(-50%);
    color: white;
    font-size: 9px;
    background: #270949;

    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
    }
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }


`;

export default MobileGalleriumGallery;