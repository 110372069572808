import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLORS } from '../styles/theme'

const PinWrapper = styled.div`
  ${(props) => props.css}
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    box-shadow: 0px 25px 10px -15px ${COLORS.primaryTrans};
  }
`

export default function BoothEntry({
  id,
  css,
}) {

  return (
    <PinWrapper css={css} as={Link} to={`/booths/${id}`}>
      <div className="pins"></div>
    </PinWrapper>
  )
}
