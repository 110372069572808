import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js';
import routes from '../../routes';
import { toggleMute } from '../../ducks/layout';

import LoadIcon from '../../components/LoadIcon';

import landing from '../../images/mobile/landing.jpg';
import lobby from '../../images/mobile/lobby.jpg';
import logo from '../../images/event-logo.png';
import kfair20 from '../../images/mobile/kfair20.png';
import kfair20Tagline from '../../images/mobile/kfair20-tagline.png';
import kwspWhite from '../../images/mobile/kwsp-white.png';
import emc from '../../images/mobile/emc.png';
import emc2 from '../../images/mobile/emc2.png';
import living from '../../images/mobile/living.png';
import meeting from '../../images/mobile/meeting.png';
import mute from '../../images/mobile/mute.png';
import expo from '../../images/mobile/expo.png';
import gallerium from '../../images/mobile/gallerium.png';
import networking from '../../images/mobile/networking.png';


const MobileLobby = ({ currentUser }) => {
  const dispatch = useDispatch();
  const { muted } = useSelector((state) => state.layout);
  const [loading, setLoading] = useState(true);

  const toggleAudio = () => {
    // amplitude.getInstance().logEvent('Click Mute button');
    dispatch(toggleMute())
  };

  // useEffect(() => {
  //   ReactGA.pageview(`/lobby`)
  //   amplitude.getInstance().logEvent('Visit Lobby');
  // }, [currentUser.id])

  return (
    <div>
      <Page>
        <img className='my-5 mx-auto d-block' width="45%" src={logo}/>
        {/* <img className='living' src={living}/> */}
        {/* <img className='kwsp-white' src={kwspWhite}/> */}
        {/* <img className='emc' src={emc}/> */}
        {/* <div className='video-wrapper'> */}
        {/*   {loading && <LoadIcon />} */}
        {/*   <iframe */}
        {/*     width="100%" */}
        {/*     height="100%" */}
        {/*     title="Event Trailer" */}
        {/*     src="https://player.vimeo.com/video/488018441?autoplay=1&loop=1" */}
        {/*     allow="autoplay; fullscreen" */}
        {/*     allowFullScreen */}
        {/*     frameBorder="0" */}
        {/*     onLoad={() => { */}
        {/*       setLoading(false) */}
        {/*     }} */}
        {/*   /> */}
        {/* </div> */}
        {/* <div className='video-wrapper'></div> */}
        <div className='menu'>
          <p className='title'>Virtual Halls</p>
          {/* <div className='mute-button' onClick={toggleAudio}>
            <img className='mute-icon' src={mute}/>
            <p className='mute-label'>{muted ? 'Unmute' : 'Mute'}</p>
          </div> */}
          <div className='list-wrapper'>

            <div className='item-box'>
              <div className='icon-wrapper'>
                <img className='meeting1' src={logo} />
              </div>
              <p className='item-label'>Auditorium</p>
              <Link to={routes.auditorium1} className='item-button'>Enter</Link>
            </div>

            {/* {
              currentUser.role === 'manager' &&
              <div className='item-box'>
                <div className='icon-wrapper'>
                  <img className='meeting2' src={emc2}/>
                </div>
                <p className='item-label'>MC20 Conference Hall</p>
                <Link to={routes.auditorium2} className='item-button'>Enter</Link>
              </div>
            } */}

            <div className='item-box'>
              <div className='icon-wrapper'>
                <img className='expo' src={expo}/>
              </div>
              <p className='item-label'>Exhibition Hall</p>
              <Link to={routes.exhibition1} className='item-button'>Enter</Link>
            </div>

            {/* <div className='item-box'>
              <div className='icon-wrapper'>
                <img className='expo' src={expo}/>
              </div>
              <p className='item-label'>Exhibition Hall B</p>
              <Link to={routes.exhibition2} className='item-button'>Enter</Link>
            </div> */}

            <div className='item-box'>
              <div className='icon-wrapper'>
                <img className='networking' src={networking}/>
              </div>
              <p className='item-label'>Networking</p>
              <Link to={routes.networking} className='item-button'>Enter</Link>
            </div>

            {/* <div className='item-box'>
              <div className='icon-wrapper'>
                <img className='gallerium' src={gallerium}/>
              </div>
              <p className='item-label'>Library</p>
              <Link to={routes.gallerium} className='item-button'>Enter</Link>
            </div> */}

          </div>
        </div>
      </Page>
    </div>
  );
}

const Page = styled.div`
  /*background: #0c1750;*/
  background: url(${landing});
  background-size: cover;
  background-position: 70% 50%;

  height: calc(100% - 60px);
  width: 100vw;
  overflow: hidden;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  /*.logo {
    width: 100px;
    position: absolute;
    top: 15%;
    left: 33%;
    transform: translateX(-50%);
  }

  .emc {
    width: 100px;
    position: absolute;
    top: 15%;
    left: 67%;
    transform: translateX(-50%);
  }

  .kwsp-white {
    width: 100px;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
  }*/

  .logo {
    width: 130px;
    /*width: 30%;*/
    position: absolute;
    top: 35%;
    left: 27%;
    transform: translateX(-50%);
  }

  .emc {
    width: 140px;
    /*width: 30%;*/
    position: absolute;
    top: 35%;
    left: 73%;
    transform: translateX(-50%);
  }

  .kwsp-white {
    width: 130px;
    /*width: 30%;*/
    position: absolute;
    top: calc(35% - 120px);
    left: 50%;
    transform: translateX(-50%);
  }

  .living {
    width: 150px;
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
  }

  .video-wrapper {
    width: 100%;
    
    position: absolute;
    bottom: 230px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;

    /*background: black;*/
    padding-bottom: 56.25%;
    height: 0;

    background: url(${lobby});
    background-size: cover;
    background-position: 50% 50%;
    /*height: 200px;*/

    iframe {
      position: absolute;
    }
  }

  .menu {
    width: 100%;
    height: 250px;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    background: white;
    border-radius: 20px 20px 0px 0px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .mute-button {
    width: 80px;
    height: 22px;
    /*background: orange;*/
    position: absolute;
    top: 22px;
    right: 20px;
  }

  .mute-icon {
    height: 15px;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  .mute-label {
    font-size: 12px;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  .list-wrapper {
    width: 100%;
    height: 180px;
    position: absolute;
    top: 50px;
    left: 0;
    /*background: grey;*/
    /*opacity: 0.5;*/
    overflow-x: scroll;
    display: flex;
    align-items: center;
  }

  .item-box {
    width: 130px;
    height: 160px;
    margin-left: 20px;
    border: solid 3px #720105;
    border-radius: 10px;
    background: white;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .icon-wrapper {
    width: 60px;
    height: 60px;
    /*background: orange;*/
    /*opacity: 0.5;*/
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-label {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    color: #272727;
    margin: 0px;
  }

  .item-button {
    background-color: #270949;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: bold;
    width: 90px;
    height: 25px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .meeting1 {
    width: 85%;
    transform: translateY(15%);
  }

  .meeting2 {
    width: 85%;
  }

  .meeting {
    width: 150%;
  }

  .expo {
    width: 170%;
  }

  .gallerium {
    width: 140%;
  }

  .networking {
    width: 110%;
  }

  @media screen and (min-width: 540px) {
    .video-wrapper {
      width: 500px;
      height: 281.25px;
      padding-bottom: 0;

      
      position: absolute;
      /*top: 30%;*/
      bottom: 230px;
      left: 50%;
      transform: translateX(-50%);      
    }

    .logo {
      width: 200px;
      /*width: 30%;*/
      position: absolute;
      top: 35%;
      left: 27%;
      transform: translateX(-50%);
    }

    .emc {
      width: 210px;
      /*width: 30%;*/
      position: absolute;
      top: 35%;
      left: 73%;
      transform: translateX(-50%);
    }

    .kwsp-white {
      width: 200px;
      /*width: 30%;*/
      position: absolute;
      top: calc(35% - 180px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export default MobileLobby;