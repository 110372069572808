import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../../ducks/auth'
import { Redirect, Link } from 'react-router-dom'

import routes from '../../routes'

import logo from '../../images/event-logo.png'
import Logo2 from './components/Logo2.js'
import Message from './components/Message.js'
import FormWrapper from './components/FormWrapper.js'

const MobileLogin = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(
      loginUser({
        email,
        password,
      })
    )
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  }

  return (
    <div>
      {/* <Logo2 /> */}
      <img src={logo} alt="logo" width="30%" className="mt-4 mx-auto d-block" />
      <Message
        title="Welcome"
        subtitle="Enter Email and Password to login"
      />
      <FormWrapper>
        <form onSubmit={handleLogin}>
          <input
            value={email}
            onChange={onEmailInput}
            type="email"
            placeholder="Email"
          />
          <input
            value={password}
            onChange={onPasswordInput}
            type="password"
            placeholder="Password"
          />
          <button
            disabled={!(email.length && password.length) || isFetching}
            type="submit"
            className="button"
          >
            {isFetching ? 'Submitting' : 'Submit'}
          </button>
        </form>
        <Link to={routes.resetPassword} className="form-link">
          Forgot password?
        </Link>
      </FormWrapper>
    </div>
  )
}

export default MobileLogin
