import HSBC from '../images/booth/HSBC.jpg'
import ELC from '../images/booth/Boehringer.jpg'
import ESTEELAUDER from '../images/booth/ESTEELAUDER.jpg'
import MSD from '../images/booth/MSD.jpg'
import LOREAL from '../images/booth/LOREAL.jpg'
import AIA from '../images/booth/AIA.jpg'
import Bayer from '../images/booth/Bayer.jpg'
import Manulife from '../images/booth/Manulife.jpg'
import Boehringer from '../images/booth/Boehringer.jpg'

import AWS from '../images/booth/AWS.jpg'
import ESport from '../images/booth/ESport.jpg'
import Microsoft from '../images/booth/Microsoft.jpg'
import MDEC from '../images/booth/MDEC.jpg'
import EmpireProject from '../images/booth/EmpireProject.jpg'
import SyriaCare from '../images/booth/SyriaCare.jpg'
import TM from '../images/booth/TM.jpg'
import Prudential from '../images/booth/Prudential.jpg'
import GamesBond from '../images/booth/GamesBond.jpg'

import {getButtonPos, getPrevNext} from '../utils/boothHelper'

// ELC materials
const boothMaterial = {
  navigate: getPrevNext(6),
  background_image: {
    url : "https://kfair20.s3.ap-southeast-1.amazonaws.com/0r5xrbo7mybet3qimfwx3unv4hch",
  },
  btnPos : {
    vid: `
      top: 80%;
      left: 1%;
    `,
    image: `
      top: 80%;
      left: 28%;
    `,
    chat: `
      top: 80%;
      left: 18.9%;
    `,
    pdf: `
      top: 80%;
      left: 9.8%;
    `,
    info: `
      top: 80%;
      left: 37%;
    `,
  },
  video_urls: [
    "https://player.vimeo.com/video/499882010",
  ],
  images: [{ url:"https://kfair20.s3.ap-southeast-1.amazonaws.com/p3rj84zt1bficdt63htq27r65prq"}],
  documents: [{
    filename: "LX_Brochure_v2.pdf",
    url: "https://kfair20.s3.ap-southeast-1.amazonaws.com/m0olmbe2py49c3yll1xyur8s79o4",
  }],
  title: "ELC - Learning Xperience",
  description: "ELC - Learning Xperience",
  content: `Website: <a href="https://learningxperience.kwsp.gov.my">https://learningxperience.kwsp.gov.my</a>`,
  booth_agents: [
    {
      name: "Elaine Chong - The DreamCrafter & Head of Sales ",
      phone: "+6 018 329 8012",
      email: "elainechong@aaviiworldwide.com",
    },
    {
      name: "KL Yuen (Mr) - The Herald & Chief Sales Officer",
      phone: "+6 012 209 8628",
      email: "klyuen@aaviiworldwide.com",
    }
  ],
  chat_link: "https://embed.tawk.to/5fd2e56aa8a254155ab23e0f/default",
}

const hallA = [
  {
    id: "1",
    name: "Manulife",
    bgImg: Manulife, 
    boothType: 2,
    css: `
      top: 27%;
      left: 20%;
      right: 67%;
      bottom: 56.3%;
    `,
    hallName: "A",
    prevId: null,
    nextId: "2",
  },
  {
    id: "2",
    name: "ESTEELAUDER",
    bgImg: ESTEELAUDER, 
    boothType: 2,
    css: `
      top: 27%;
      left: 43.5%;
      right: 44%;
      bottom: 56.3%;
    `,
    hallName: "A",
    prevId: "1",
    nextId: "3",
  },
  {
    id: "3",
    name: "LOREAL",
    bgImg: LOREAL, 
    boothType: 2,
    css: `
      top: 27%;
      left: 67.5%;
      right: 20%;
      bottom: 56.3%;
    `,
    hallName: "A",
    prevId: "2",
    nextId: "4",
  },
  {
    id: "4",
    name: "HSBC",
    bgImg: HSBC, 
    boothType: 1,
    css: `
      top: 48%;
      left: 14%;
      right: 70.4%;
      bottom: 32.3%;
    `,
    hallName: "A",
    prevId: "3",
    nextId: "5",
  },
  {
    id: "5",
    name: "Bayer",
    bgImg: Bayer, 
    boothType: 1,
    css: `
      top: 48%;
      left: 43.2%;
      right: 43%;
      bottom: 32.3%;
    `,
    hallName: "A",
    prevId: "4",
    nextId: "6",
  },
  {
    id: "6",
    name: "Boehringer",
    bgImg: Boehringer, 
    boothType: 1,
    css: `
      top: 48%;
      left: 70.2%;
      right: 15%;
      bottom: 32.3%;
    `,
    hallName: "A",
    prevId: "5",
    nextId: "7",
  },
  {
    id: "7",
    name: 'MSD',
    bgImg: MSD, 
    boothType: 3,
    css: `
      top: 70.9%;
      left: 25.2%;
      right: 58%;
      bottom: 4.9%;
    `,
    hallName: "A",
    prevId: "6",
    nextId: "8",
  },
  {
    id: "8",
    name: 'AIA',
    bgImg: AIA, 
    boothType: 3,
    css: `
      top: 70.9%;
      left: 58.3%;
      right: 25%;
      bottom: 4.9%;
    `,
    hallName: "A",
    prevId: "7",
    nextId: null,
  },
  // {
  //   id: "9",
  //   name: 'Perbadanan Tabung Pendidikan Tinggi Nasional (PTPTN)',
  //   destination: '/booths/PTPTN',
  //   bgImg: PTPTN, 
  //   materials: boothMaterial,
  //   css: `
  //     top: 47.5%;
  //     left: 57.5%;
  //     right: 31%;
  //     bottom: 33.8%;
  //   `,
  //   hallName: "A",
  //   prevId: "8",
  //   nextId: "10",
  // },
  // {
  //   id: "10",
  //   name: 'Open University Malaysia (OUM)',
  //   destination: '/booths/OUM',
  //   bgImg: OUM, 
  //   materials: boothMaterial,
  //   css: `
  //     top: 47.5%;
  //     left: 72.5%;
  //     right: 15.5%;
  //     bottom: 33.8%;
  //   `,
  //   hallName: "A",
  //   prevId: "9",
  //   nextId: "11",
  // },
  // {
  //   id: "11",
  //   name: 'Ministry of Health (MOH)',
  //   bgImg: KKM, 
  //   materials: boothMaterial,
  //   css: `
  //     top: 68.5%;
  //     left: 8%;
  //     right: 79.5%;
  //     bottom: 8%;
  //   `,
  //   hallName: "A",
  //   prevId: "10",
  //   nextId: "12",
  // },
  // {
  //   id: "12",
  //   name: 'Amanah Saham Nasional Berhad (ASNB)',
  //   bgImg: ASNB, 
  //   materials: boothMaterial,
  //   css: `
  //     top: 68.5%;
  //     left: 25%;
  //     right: 62.5%;
  //     bottom: 8%;
  //   `,
  //   hallName: "A",
  //   prevId: "11",
  //   nextId: "13",
  // },
  // {
  //   id: "13",
  //   name: 'Gamesbond',
  //   bgImg: ASNB, 
  //   materials: boothMaterial,
  //   css: `
  //     top: 68.5%;
  //     left: 42%;
  //     right: 45.5%;
  //     bottom: 8%;
  //   `,
  //   hallName: "A",
  //   prevId: "12",
  //   nextId: "14",
  // },
  // {
  //   id: "14",
  //   name: 'Mini E-Sports (DXC)',
  //   bgImg: ASNB, 
  //   materials: boothMaterial,
  //   css: `
  //     top: 68.5%;
  //     left: 59.5%;
  //     right: 27.5%;
  //     bottom: 8%;
  //   `,
  //   hallName: "A",
  //   prevId: "13",
  //   nextId: "15",
  // },
  // {
  //   id: "15",
  //   name: 'Syria Care',
  //   bgImg: ASNB, 
  //   materials: boothMaterial,
  //   css: `
  //     top: 68.5%;
  //     left: 76%;
  //     right: 11%;
  //     bottom: 8%;
  //   `,
  //   hallName: "A",
  //   prevId: "14",
  //   nextId: null,
  // },
]

const hallB = [
  {
    id: "13",
    name: 'Amazon Web Services (AWS)',
    bgImg: AWS, 
    materials: boothMaterial,
    css: `
      top: 37.5%;
      left: 12%;
      right: 76%;
      bottom: 42%;
    `,
    hallName: "B",
    prevId: "13",
    nextId: "18",
  },
  {
    id: "18",
    name: 'Malaysia Digital Economy Corporation (MDEC)',
    bgImg: MDEC, 
    materials: boothMaterial,
    css: `
      top: 37.5%;
      left: 27.5%;
      right: 60.5%;
      bottom: 42%;
    `,
    hallName: "B",
    prevId: "13",
    nextId: "19",
  },
  {
    id: "19",
    name: 'Telekom Malaysia Berhad (TM)',
    bgImg: TM, 
    materials: boothMaterial,
    css: `
      top: 37.5%;
      left: 42.5%;
      right: 45.5%;
      bottom: 42%;
    `,
    hallName: "B",
    prevId: "18",
    nextId: "21",
  },
  {
    id: "21",
    name: 'Gamesbond',
    bgImg: GamesBond, 
    materials: boothMaterial,
    css: `
      top: 37.5%;
      left: 58.5%;
      right: 30%;
      bottom: 42%;
    `,
    hallName: "B",
    prevId: "19",
    nextId: "22",
  },
  {
    id: "22",
    name: 'Mini E-Sports (DXC)',
    bgImg: ESport, 
    materials: boothMaterial,
    css: `
      top: 37.5%;
      left: 74%;
      right: 14%;
      bottom: 42%;
    `,
    hallName: "B",
    prevId: "21",
    nextId: "23",
  },
  {
    id: "23",
    name: 'Syria Care',
    bgImg: SyriaCare, 
    materials: boothMaterial,
    css: `
      top: 66%;
      left: 12.5%;
      right: 72.5%;
      bottom: 7%;
    `,
    hallName: "B",
    prevId: "22",
    nextId: "24",
  },
  {
    id: "24",
    name: 'Persatuan Empayar Kebajikan Malaysia (EMPIRE PROJECT)',
    bgImg: EmpireProject, 
    materials: boothMaterial,
    css: `
      top: 66.5%;
      left: 32.5%;
      right: 54.5%;
      bottom: 7%;
    `,
    hallName: "B",
    prevId: "23",
    nextId: "25",
  },
  {
    id: "25",
    name: 'Prudential Malaysia',
    bgImg: Prudential, 
    materials: boothMaterial,
    css: `
      top: 66.5%;
      left: 51%;
      right: 35%;
      bottom: 7%;
    `,
    hallName: "B",
    prevId: "24",
    nextId: "26",
  },
  {
    id: '26',
    name: 'Microsoft',
    bgImg: Microsoft, 
    materials: boothMaterial,
    css: `
      top: 66.5%;
      left: 69.5%;
      right: 16%;
      bottom: 7%;
    `,
    hallName: "B",
    prevId: "25",
    nextId: "26",
  },

]
  
export { hallA, hallB, boothMaterial }