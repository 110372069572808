import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js';

import PageWithBg from '../components/PageWithBg'
import LobbyOverlay from '../components/LobbyOverlay'
import LobbyEntry from '../components/LobbyEntry'
import { useDispatch } from 'react-redux'
import { showGlobalModal } from '../ducks/layout'
import config from '../config'
import { loadTawk } from '../utils/tawkToHelper'

const PageWrapper = styled.div`
  .trailer {
    background-color: black;
    position: absolute;
    top: 13.5%;
    left: 33.7%;
    right: 34.7%;
    bottom: 54.9%;
  }
`

export default function LobbyPage({ currentUser }) {
  const [overlayTitle, setOverlayTitle] = useState('Select hall to enter:')
  const [overlay, setOverlay] = useState(null)
  const dispatch = useDispatch()

  const showEntryOverlay = (destinations) => {
    setOverlay(destinations)
  }

  const hideOverlay = () => setOverlay(null)

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      hideOverlay()
    }
  }, [])

  const showHelpDesk = () => {
    dispatch(showGlobalModal('message'))
    // const cpt = window.chaport
    // cpt.on('ready', function () {
    //   cpt.setVisitorData({
    //     name: currentUser.fullName,
    //     email: currentUser.email,
    //   })
    //   cpt.open()
    // })
    // cpt.on('collapsed', function () {
    //   cpt.close()
    // })
    // amplitude.getInstance().logEvent('Click Lobby Helpdesk');
  }

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
  }, [])

  // useEffect(() => {
  //   ReactGA.pageview(`/lobby`)
  //   amplitude.getInstance().logEvent('Visit Lobby');
  // }, [currentUser.id])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <PageWrapper>
      <PageWithBg bgImg={config.assets.lobby.landscape}>
        <LobbyOverlay
          overlayTitle={overlayTitle}
          overlay={overlay}
          hideOverlay={hideOverlay}
        />
        {config.lobbyEntries.map((entry) => {
          // temporary hardcoded for KFAIR20 access
          // if (entry.id === 'auditorium') {
          //   const audOptions = [...entry.destination]

          //   if (currentUser?.role === 'manager') {
          //     entry.destination = [audOptions[0], audOptions[1]]
          //   }

          //   if (currentUser?.role === 'non_manager') {
          //     entry.destination = [audOptions[0]]
          //   }
          // }

          return (
            <LobbyEntry
              showHelpDesk={showHelpDesk}
              key={entry.id}
              setOverlayTitle={setOverlayTitle}
              showEntryOverlay={showEntryOverlay}
              {...entry}
            />
          )
        })}
        {/* <div className="trailer">
          <iframe
            width="100%"
            height="100%"
            title="Event Trailer"
            src="https://player.vimeo.com/video/488018441?autoplay=1&loop=1"
            allow="autoplay; fullscreen"
            allowFullScreen
            frameBorder="0"
          />
        </div> */}
      </PageWithBg>
    </PageWrapper>
  )
}
