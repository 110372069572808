import axios from 'axios'
import { toast } from 'react-toastify'
import config from '../config'
import { db } from '../constants/firebaseConfig'

const LOGIN_REQUEST = 'LOGIN_REQUEST'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'

const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

const currentUserTypeCheck = () => {
  const storageUser = localStorage.getItem('current_user')
  let resultObj = null
  if (storageUser) {
    try {
      resultObj = JSON.parse(storageUser)
    } catch {
      localStorage.clear()
      console.log('Malformed localstorage user. Reset to null')
    }
  }
  return resultObj
}

const initialState = {
  isFetching: false,
  isAuthenticated: localStorage.getItem('jwt_token') ? true : false,
  // currentUser: {},
  currentUser: currentUserTypeCheck(),
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        currentUser: action.user,
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
      }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        currentUser: {},
      }

    default:
      return state
  }
}

export function refreshUser() {
  return (dispatch) => {
    dispatch(receiveLoginRefresh(JSON.parse(localStorage.getItem('current_user'))))
    // return axios
    //   .get(`${config.apiUrl}/api/v1/auto_login`, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
    //     },
    //   })
    //   .then(({ status, data }) => {
    //     if (status === 200 && data.success) {
    //       dispatch(receiveLoginRefresh(data.user))
    //     } else {
    //       dispatch(rejectLogin('Failed to refresh user'))
    //     }
    //   })
    //   .catch((err) => {
    //     dispatch(rejectLogin('Fail to refresh user (Rejected)'))
    //     console.error('Error: ', err)
    //   })
  }
}

export function loginUser(credentials) {
  return (dispatch) => {
    dispatch(requestLogin())

    if (credentials.email === "demo@aaviiworldwide.com" && credentials.password === "iloveava"){
      const user = {
        email: "demo@aaviiworldwide.com",
        fullName: "John Smith",
        firstName: "John",
        lastName: "Smith",
        companyName: "ABC Co.",
      }
      localStorage.setItem('jwt_token', Math.random().toString(36).substr(2))
      localStorage.setItem('current_user', JSON.stringify(user))
      dispatch(receiveLogin(user))
    }
    else {
      dispatch(rejectLogin("Invalid login credentials"))
    }

    // return axios
    //   .post(`${config.apiUrl}/api/v1/login`, credentials)
    //   .then(({ status, data }) => {
    //     if (status === 200 && data.success) {
    //       localStorage.setItem('jwt_token', data.token)
    //       dispatch(receiveLogin(data.user))
    //       const sessionToken = Date.now().toString()
    //       const sessionRef =
    //         process.env.REACT_APP_STAGE === 'production'
    //           ? `session/${data.user.id}`
    //           : `session/local/${data.user.id}`
    //       db.ref(sessionRef).set(sessionToken)
    //       localStorage.setItem('session_token', sessionToken)
    //     } else {
    //       dispatch(rejectLogin(data.error))
    //     }
    //   })
    //   .catch((err) => {
    //     dispatch(rejectLogin(err.response?.data?.message))
    //     console.error('Error: ', err)
    //   })
  }
}

const clearUserSession = () => {
  localStorage.removeItem('jwt_token')
  localStorage.removeItem('current_user')
  localStorage.removeItem('session_token')
}

export function logoutUser() {
  toast('You are logged out!', {
    toastId: 'logoutToast',
  })
  return (dispatch) => {
    clearUserSession()
    dispatch(receiveLogout())
  }
}

export function kickUser() {
  toast('You have logged in from another browser!', {
    toastId: 'logoutToast',
  })
  return (dispatch) => {
    clearUserSession()
    dispatch(receiveLogout())
  }
}

function requestLogin() {
  return {
    type: LOGIN_REQUEST,
  }
}

function receiveLoginRefresh(user) {
  return {
    type: LOGIN_SUCCESS,
    user,
  }
}

function receiveLogin(user) {
  // if (user.role === 'manager') {
  //   toast(`Hello ${user.fullName}, welcome to MC20!`)
  // } else {
  //   toast(`Hello ${user.fullName}, welcome to KFAIR20!`)
  // }
  // toast(`Hello ${user.fullName}, welcome to EPF Virtual Event!`)
  toast(`Hello ${user.fullName}, welcome to ${config.eventName}!`)
  return {
    type: LOGIN_SUCCESS,
    user,
  }
}

function rejectLogin(message) {
  toast(message)
  return {
    type: LOGIN_FAILURE,
  }
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  }
}
