import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js'
import { useDispatch, useSelector } from 'react-redux'
import { kickUser, refreshUser } from '../ducks/auth'
import routes from '../routes'
import config from '../config'
import TopNav from '../components/TopNav'
import MobileNav from '../components/MobileNav'
import NoticeBar from '../components/NoticeBar'
import { useObject } from 'react-firebase-hooks/database'
import { db } from '../constants/firebaseConfig'

export default function PrivateRoute(props) {
  const {
    fixedNav = false,
    mobileNav = false,
    component: Component,
    authed,
    currentUser,
    navOptions,
    navColor,
    ...rest
  } = props
  const dispatch = useDispatch()
  const { muted } = useSelector((state) => state.layout)

  // const sessionRef =
  //   process.env.REACT_APP_STAGE === 'production'
  //     ? `session/${currentUser?.id}`
  //     : `session/local/${currentUser?.id}`

  // const [snapshot, loading, error] = useObject(db.ref(sessionRef))

  // if (!loading && !error && snapshot.val()) {
  //   if (snapshot.val() !== localStorage.getItem('session_token')) {
  //     dispatch(kickUser())
  //   }
  // }

  // useEffect(() => {
  //   ReactGA.initialize(config.googleAnalyticsID, {
  //     gaOptions: {
  //       userId: currentUser.id,
  //     },
  //   })
  //   if (currentUser) {
  //     amplitude.getInstance().setUserId(currentUser.email)
  //     amplitude.getInstance().setUserProperties({ ...currentUser })
  //   }
  // }, [currentUser, currentUser.email])

  useEffect(() => {
    if (authed) {
      dispatch(refreshUser())
    }
  }, [authed, dispatch])

  return (
    <Route
      {...rest}
      render={(props) =>
        authed && currentUser ? (
          <>
            {fixedNav && (
              <>
                <TopNav
                  currentUser={currentUser}
                  fixedNav={fixedNav}
                  navColor={navColor}
                />
                <NoticeBar />
              </>
            )}
            {
              mobileNav &&
              <MobileNav />
            }
            <Component currentUser={currentUser} {...props} />
            {/* <audio
              loop
              autoPlay
              muted={muted}
              style={{ visibility: 'hidden' }}
              src={config.assets.lobbyMusic}
            ></audio> */}
          </>
        ) : (
          <Redirect
            to={{ pathname: routes.login, state: { from: props.location } }}
          />
        )
      }
    />
  )
}
