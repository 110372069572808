import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { useListVals } from 'react-firebase-hooks/database'
import { NotificationDb } from '../../constants/firebaseConfig'
import { useHistory } from 'react-router-dom'
import LoadIcon from '../LoadIcon'
import { COLORS } from '../../styles/theme'
import ReactTimeAgo from 'react-time-ago'
import routes from '../../routes'

const StyledModal = styled(Modal)`
  color: white;
  text-align: left;
  /* mix-blend-mode: hard-light; */
  
  .modal-content {
    background-image: linear-gradient(rgba(113, 3, 11, 1), rgba(39, 13, 72, 1));
    opacity: 0.94;
    border: none;
    border-radius: 0;
    padding: 24px 72px;
    margin-bottom: 24px;

    .modal-header {
      color: #FFAD2C;
      border: none;
      .modal-title {
        font-size: 36px;
        font-weight: bold;
        font-style: italic;
      }
      button {
        font-size: 36px;
        opacity: 1;
        color: white;
      }
    }
  }
  .divider {
    background: linear-gradient(270deg, #FE1E9A, #254DDE);
    height: 4.5px;
  }
  .modal-body {
    padding-bottom: 36px;
    section {
      margin-bottom: 36px;
      h4 {
        font-style: italic;
        font-size: 23px;
        font-weight: bold;
      }
    }
  }
  #refresh-btn {
    border: solid 1px ${COLORS.primary};
    background: #FFAD2C;
    padding: 5px 10px;
    border-radius: 16px;
    margin: 36px auto;
    display: block;
  }
`
const MessageWrapper = styled.div`
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.text-content {
    width: 85%;
    border-bottom: 3px solid rgba(197, 160, 252, 0.5);
    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
    small.timestamp {
      font-size: 0.8em;
      color: rgba(255, 255, 255, 0.55);
      margin-bottom: 16px;
    }
  }
  div.btn-container {
    width: 15%;
    padding: 0px 24px;
    text-align: center;
    button.action-btn {
      background: #C5A0FC;
      color: #272727;
      text-decoration: none;
      border-radius: 12px;
      padding: 6px 16px;
      font-weight: 500;
      border: none;
    }
  }
`
export default function NotificationModal({ showModal, hideSchedule }) {
  const [values, loading, error] = useListVals(NotificationDb)
  const history = useHistory()

  const refreshPage = () => {
    window.location.reload()
  }
  const handleRedirect = (url) => {
    hideSchedule()
    history.push(url)
  }

  return (
    <StyledModal
      show={showModal}
      onHide={hideSchedule}
      size="xl"
      aria-labelledby="notification-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>NOTIFICATIONS</Modal.Title>
      </Modal.Header>
      <div className="divider"></div>
      <Modal.Body>
        <section>
          {loading ? (
              <LoadIcon />
            ) : error ? (
              <button id="refresh-btn" onClick={refreshPage}>
                Connection failed. Click here to refresh.
              </button>
            ) : (
              // <MessageWrapper>
              //   <div className="text-content">
              //     <p>{notification.message}</p>
              //     <small className="d-block timestamp">
              //       {notification.datetime && <ReactTimeAgo date={notification.datetime} />}
              //     </small>
              //   </div>
              //   <div className="btn-container">
              //     {
              //       notification.url && (
              //         <button onClick={()=>handleRedirect(notification.url)} className="action-btn">
              //           View
              //         </button>
              //       )
              //     }
              //   </div>
              // </MessageWrapper>
              values.sort((a,b)=> new Date(b.datetime) - new Date(a.datetime)).map((item, index)=>{
                return (
                  <MessageWrapper key={`notify-${index}`}>
                    <div className="text-content">
                      <p>{item.message}</p>
                      <small className="d-block timestamp">
                        {item.datetime && <ReactTimeAgo date={item.datetime} />}
                      </small>
                    </div>
                    <div className="btn-container">
                      {
                        item.url && (
                          <button onClick={()=>handleRedirect(item.url)} className="action-btn">
                            View
                          </button>
                        )
                      }
                    </div>
                  </MessageWrapper>
                )
              })
            )
          }
        </section>
      </Modal.Body>
    </StyledModal>
  )
}
