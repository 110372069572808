import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import LoadIcon from '../../components/LoadIcon'
import ChatSection from '../../components/ChatSection.js'
import SideAgenda from '../../components/SideAgenda.js'
import { muteMusic, unmuteMusic } from '../../ducks/layout'
import openTheatre from '../../images/mobile/open-theatre.png'
import closeTheatre from '../../images/mobile/close-theatre.png'
import screen from '../../images/mobile/screen.png'
import checkinPlayback from '../../images/mobile/checkin-playback.png'
import kfair20Bg from '../../images/mobile/kfair20-bg.jpg'
import kfair20Title from '../../images/mobile/kfair20-title.png'
import { AuditoriumDb, CheckInDb, ShowSession } from '../../constants/firebaseConfig'
import { useList, useObject } from 'react-firebase-hooks/database'

const MobileKFair20 = ({ currentUser }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  const [isLoading, setIsLoading] = useState(true)
  const [sidebar, setSidebar] = useState(false)
  const [checkin, setCheckin] = useState([])
  const [snapshot, sessionLoading, sessionError] = useObject(ShowSession)
  const [snapshots, loading, error] = useList(CheckInDb)
  const [theatre, setTheatre] = useState(false)
  const groudId = [ [1,2,3,4], [5]]

  const [ agendaList, setAgendaList ] = useState([
    {
      id: 1,
      start: "11:00 AM",
      end: "12:15 PM",
      desc: "Stay Safe Online: Take Control of Your Digital Life",
      attendance: false,
      url: 505055532,
    },
    {
      id: 2,
      start: "02:15 PM",
      end: "02:30 PM",
      desc: "Conquering 2020 in the New Normal",
      attendance: false,
      url: 505055532,
    },
    {
      id: 3,
      start: "02:45 PM",
      end: "03:30 PM",
      desc: "Pandemic Crisis: Global Economic Recovery",
      attendance: false,
      url: 505055532,
    },
    {
      id: 4,
      start: "03:45 PM",
      end: "04:30 PM",
      desc: "Dealing with Change: Turning the Mind Towards Acceptance",
      attendance: false,
      url: 505055532,
    },
    {
      id: 5,
      start: "04:45 PM",
      end: "05:30 PM",
      desc: `Changing the World of "Work-Life Harmony"`,
      attendance: false,
      url: 505055532,
    },
  ])
  useEffect(() => {
    if (snapshot) {
      let filtered = snapshots.filter(item => groudId[parseInt(snapshot.val())].includes(item.val().id))
      const checkInList = []
      for (let i = 0; i < filtered.length;i++){
        let selected = agendaList.filter(item=>item.id==filtered[i].val().id)
        checkInList.push({
          ...selected[0],
          active: filtered[i].val().active,
        })
      }
      setCheckin(checkInList)
      // Axios({
      //   url: `${config.apiUrl}/api/v1/hall_sessions?hall_id=1`,
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      //   },
      // }).then((result) => {
      //   let withVideo = result.data.sessions.map((item) => {
      //     return {
      //       ...item,
      //       url: videoList[item.id],
      //     }
      //   })
      //   setAgendaList(withVideo)
      //   const checkInList = []
      //   for (let i = 0; i < filtered.length;i++){
      //     let selected = result.data.sessions.filter(item=>item.id==filtered[i].val().id)
      //     checkInList.push({
      //       ...selected[0],
      //       active: filtered[i].val().active,
      //     })
      //   }
      //   console.log(checkInList)
      //   setCheckin(checkInList)
      // })
    }
  }, [snapshots, sessionLoading, snapshot])
  const videoList = {
    1: '505055532',
    2: '505055532',
    3: '505055532',
    4: '505055532',
    5: '505055532',
  }
  const toggleSidebar = () => {
    // amplitude.getInstance().logEvent(`Click KFAIR20 checkin list`);
    setSidebar(!sidebar)
  }
  const checkinSession = (session) => {
    const newAgendaList = agendaList.map((item, index) => ({
      ...item,
      attendance: item.id === session.id ? true : item.attendance,
    }))
    setAgendaList(newAgendaList)
    const newCheckinList = checkin.map((item, index) => ({
      ...item,
      attendance: item.id === session.id ? true : item.attendance,
    }))
    setCheckin(newCheckinList)
  }

  const videoContainerTheatreStyle = {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'black',
    zIndex: '99',
  }

  return (
    <Page>
      <SideAgenda
        open={sidebar}
        toggleSidebar={toggleSidebar}
        agendaList={agendaList}
        isLoading={isLoading}
        takeAttendance={checkinSession}
        setAgendaList={setAgendaList}
        hallNumber={1}
        currentUser={currentUser}
      />

      <div className="outer-wrapper">
        <div className="container">
          {/* <div className="checkin-container">
            <img
              src={checkinPlayback}
              className="checkin"
              onClick={toggleSidebar}
            />
          </div> */}

          <div className="screen-container">
            <img src={screen} className="screen" />
            <div className="video-container" style={theatre ? videoContainerTheatreStyle : {}}>
              {loading && <LoadIcon />}
              <iframe
                title="Live Broadcast"
                src="https://player.vimeo.com/video/567309405?autoplay=1&loop=1"
                allow="autoplay; fullscreen"
                allowFullScreen
                frameBorder="0"
                onLoad={() => {
                  setIsLoading(false)
                }}
              />
            </div>
          </div>

          <button type='button' className='theatre-button' onClick={() => setTheatre(true)}>
            <img src={openTheatre} className='theatre-button-icon'/>
            <p className='theatre-button-label'>Theatre mode</p>
          </button>

          <img src={kfair20Title} className="title" />
        </div>

        <div className="chat-container">
          {/* <iframe 
            title="crowdpurr" 
            src={`https://www.crowd.live/BZ9H2`} 
            height="100%" 
            width="100%" 
            frameBorder="0" 
            style={{minHeight: '560px'}}
          ></iframe> */}
          <ChatSection 
            currentUser={currentUser} 
            firebaseDb={AuditoriumDb}
            hallName="KFAIR20" 
          />
        </div>

        {
          theatre &&
          <div className='theatre-close'>
            <img src={closeTheatre} onClick={() => setTheatre(false)} className='theatre-close-icon'/>
          </div>
        }
        
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${kfair20Bg});
  background-size: cover;
  background-position: 50% 50%;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 95%;
    margin: auto;
  }

  .checkin-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0px 20px 0px;
  }

  .checkin {
    width: 38%;
    height: auto;
  }

  .screen-container {
    width: 100%;
    height: 0;
    padding-bottom: 58.5%;
    position: relative;
  }

  .screen {
    width: 100%;
    position: absolute;
  }

  .video-container {
    width: 92%;
    height: 81%;
    background: #1e1e1e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -51%);

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  .title {
    width: 100%;
    margin: 20px 0px;
  }

  .chat-container {
    width: 100%;
    height: 500px;
  }

  .theatre-button {
    width: 140px;
    height: 30px;
    background: black;
    margin: 10px 10px 0px 0px;
    float: right;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 0.6;
    padding: 0;
  }

  .theatre-button-label {
    color: white;
    font-size: 12px;
    margin: 0px 10px 0px 0px;
  }

  .theatre-button-icon {
    width: 20px;
    margin: 0px 10px;
  }

  .theatre-close {
    color: white;
    background: black;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 99;
    opacity: 0.6;
  }

  .theatre-close-icon {
    width: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileKFair20
