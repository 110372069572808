import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

import landing from '../../images/mobile/landing.jpg';

import routes from '../../routes.js';

import MobileLanding from './MobileLanding.js';
import MobileLogin from './MobileLogin.js';
import MobileForgotPassword from './MobileForgotPassword.js';


const MobileEntrance = () => {
  return (
    <div>
      <Page>
        <Switch>

          <Route exact path={routes.root}>
            <MobileLanding />
          </Route>

          <Route exact path={routes.login}>
            <MobileLogin />
          </Route>

          <Route exact path={routes.resetPassword}>
            <MobileForgotPassword />
          </Route>

        </Switch>
      </Page>
    </div>
  );
}

const Page = styled.div`
  background: url(${landing});
  background-size: cover;
  background-position: 70% 50%;
  /*background: #0c1750;*/
  /*height: 100vh;*/
  height: 100%;
  width: 100vw;
  overflow: hidden;
  position: absolute;
`

export default MobileEntrance;