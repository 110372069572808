const getPrevNext = (boothId) => {
  switch (boothId) {
    // hall A
    case 1: 
      return {
        prev: null,
        next: 2,
      }
    case 2: 
      return {
        prev: 1,
        next: 3,
      }
    case 3: 
      return {
        prev: 2,
        next: 4,
      }
    case 4: 
      return {
        prev: 3,
        next: 5,
      }
    case 5: 
      return {
        prev: 4,
        next: 6,
      }
    case 6: 
      return {
        prev: 5,
        next: 7,
      }
    case 7: 
      return {
        prev: 6,
        next: 8,
      }
    case 8: 
      return {
        prev: 7,
        next: null,
      }
    
    // hall B
    case 13: 
      return {
        prev: null,
        next: 18,
      }
    case 18: 
      return {
        prev: 13,
        next: 19,
      }
    case 19: 
      return {
        prev: 18,
        next: 21,
      }
    case 21: 
      return {
        prev: 19,
        next: 22,
      }
    case 22: 
      return {
        prev: 21,
        next: 23,
      }
    case 23: 
      return {
        prev: 22,
        next: 24,
      }
    case 24: 
      return {
        prev: 23,
        next: 25,
      }
    case 25: 
      return {
        prev: 24,
        next: 26,
      }
    case 26: 
      return {
        prev: 25,
        next: null,
      }
    default:
      return {}
  }
}
const getButtonPos = (boothType) => {
  switch (boothType){
    case 1: // booth 4-6
      return {
        vid: `
          top: 80%;
          left: 54%;
        `,
        image: `
          top: 80%;
          left: 81.4%;
        `,
        chat: `
          top: 80%;
          left: 72.3%;
        `,
        pdf: `
          top: 80%;
          left: 63.1%;
        `,
        info: `
          top: 80%;
          left: 90.4%;
        `,
      }
    case 2: // booth 1-3
      return {
        vid: `
          top: 82%;
          left: 6%;
        `,
        image: `
          top: 82%;
          left: 33%;
        `,
        chat: `
          top: 82%;
          left: 23.9%;
        `,
        pdf: `
          top: 82%;
          left: 14.8%;
        `,
        info: `
          top: 82%;
          left: 42%;
        `,
      }
    case 3: // booth 7&8
      return {
        vid: `
          top: 80%;
          left: 1%;
        `,
        image: `
          top: 80%;
          left: 28%;
        `,
        chat: `
          top: 80%;
          left: 18.9%;
        `,
        pdf: `
          top: 80%;
          left: 9.8%;
        `,
        info: `
          top: 80%;
          left: 37%;
        `,
      }
    case 4:
      return {
        vid: `
          top: 46%;
          left: 69%;
        `,
        image: `
          top: 50%;
          left: 35%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 69%;
          left: 51%;
        `,
        info: `
          top: 18%;
          left: 63%;
        `,
        zoom: `
          top: 82.5%;
          left: 72.5%;
        `,
      }
    case 5:
      return {
        vid: `
          top: 48%;
          left: 65.5%;
        `,
        image: `
          top: 50%;
          left: 32%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 65.5%;
          left: 48.5%;
        `,
        info: `
          top: 18%;
          left: 43%;
        `,
      }
    default:
      return {}
  }
}
export { getButtonPos, getPrevNext };