import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useListVals } from 'react-firebase-hooks/database'
import { NotificationDb } from '../../constants/firebaseConfig'
import { useHistory } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'

import routes from '../../routes.js'
import LoadIcon2 from '../../components/LoadIcon2.js'

const MobileNotifications = () => {

  const [values, loading, error] = useListVals(NotificationDb)
  const history = useHistory()

  const refreshPage = () => {
    window.location.reload()
  }

  const handleRedirect = (url) => {
    history.push(url)
  }

  return (
    <div>
      <Page>
        <div className="outer-wrapper">
          <div className="container">
            <div className="title-container">
              <p className="title">Notifications</p>
              <Link className="back-button" to={routes.lobby}>
                Back
              </Link>
            </div>

            {loading ? (
              <LoadIcon2 />
              ) : error ? (
                <button onClick={refreshPage}>
                  Connection failed. Click here to refresh.
                </button>
              ) : (
                values.sort((a,b)=> new Date(b.datetime) - new Date(a.datetime)).map((item, index)=>{
                  return (
                    <div className="item-wrapper" key={index}>
                      <div className="item-content">
                        <p className='item-content-label'>{item.message}</p>
                        <p className='item-content-time'>{item.datetime && <ReactTimeAgo date={item.datetime} />}</p>
                      </div>
                      {
                        item.url && 
                        <button type="button" onClick={()=>handleRedirect(item.url)} className="item-button">
                          View
                        </button>
                      }
                    </div>
                  )
                })
              )
            }
            

            {/* <div className="item-wrapper"> */}
            {/*   <div className="item-content"> */}
            {/*     <p className='item-content-label'>Don't miss out exclusive livestream content in Hall A!</p> */}
            {/*     <p className='item-content-time'>1 hour ago</p> */}
            {/*   </div> */}
            {/*   <button type="button" className="item-button"> */}
            {/*     View Message */}
            {/*   </button> */}
            {/* </div> */}


{/*             <div className="time-wrapper"> */}
{/*               <p className="time-label">Today</p> */}
{/*               <p className="time-clear">Clear</p> */}
{/*             </div> */}
{/*  */}
{/*             <div className="item-wrapper"> */}
{/*               <div className="item-image"></div> */}
{/*               <p className="item-content"> */}
{/*                 KFAIR20 Conference is starting in 5 minutes */}
{/*               </p> */}
{/*               <button type="button" className="item-button"> */}
{/*                 Enter hall */}
{/*               </button> */}
{/*             </div> */}
{/*  */}
{/*             <div className="time-wrapper"> */}
{/*               <p className="time-label">Earlier</p> */}
{/*               <p className="time-clear">Clear</p> */}
{/*             </div> */}
{/*  */}
{/*             <div className="item-wrapper"> */}
{/*               <div className="item-image"></div> */}
{/*               <p className="item-content"> */}
{/*                 KFAIR20 Conference is starting in 5 minutes */}
{/*               </p> */}
{/*               <button type="button" className="item-button"> */}
{/*                 Enter hall */}
{/*               </button> */}
{/*             </div> */}
{/*  */}
{/*             <div className="item-wrapper"> */}
{/*               <div className="item-image"></div> */}
{/*               <p className="item-content"> */}
{/*                 Don't miss out exclusive livestream content in Hall A! */}
{/*               </p> */}
{/*               <button type="button" className="item-button"> */}
{/*                 View Message */}
{/*               </button> */}
{/*             </div> */}


          </div>
        </div>
      </Page>
    </div>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 20px 0px 10px 0px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    width: 70%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
  }

  .time-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 5px;

    p {
      margin-bottom: 0px;
    }
  }

  .time-label {
    font-size: 13px;
    font-weight: bold;
    font-style: italic;
  }

  .time-clear {
    font-size: 11px;
  }

  .item-wrapper {
    width: 100%;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 2px #e2cfff;
  }

  .item-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 2px #c69dff;
    background: lightgrey;
  }

  .item-content {
    width: 55%;
    /*margin-left: 15px;*/
    margin-left: 5px;
    margin-bottom: 0;
  }

  .item-content-label {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .item-content-time {
    font-size: 10px;
    margin-bottom: 0;
  }

  .item-button {
    width: 80px;
    padding: 5px 0;
    background: #c69dff;
    color: #272727;
    font-size: 11px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileNotifications
