import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-bootstrap';
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js';

import { boothMaterial, hallA, hallB } from '../../constants/exhibitionBooths'
import routes from '../../routes'
import { getBooth } from '../../ducks/booth'

import chevronLeft from '../../images/mobile/chevron-left.png'
import chevronRight from '../../images/mobile/chevron-right.png'
import play from '../../images/mobile/play.png'
import pdf from '../../images/mobile/pdf.png'
import image from '../../images/mobile/image.png'
import info from '../../images/mobile/info.png'
import message from '../../images/mobile/message.png'
import whatsapp from '../../images/mobile/whatsapp.png'

import LoadIcon from '../../components/LoadIcon.js'
import LoadIcon2 from '../../components/LoadIcon2.js'
import { loadTawk } from '../../utils/tawkToHelper'

const MobileExpoBooth = ({ currentUser }) => {
  const history = useHistory()
  const { boothId } = useParams()

  const [videoLoading, setVideoLoading] = useState(true);
  const [booth, setBooth] = useState(null);
  const dispatch = useDispatch();
  // const { viewBooth } = useSelector((state) => state.booth);
  const viewBooth = boothMaterial
  const [videoModal, setVideoModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [iframeModal, setIframeModal] = useState(false);

  const [videoLink, setVideoLink] = useState(null);

  const [showTawk, setShowTawk] = useState(false);


  // console.log(viewBooth);

  // useEffect(() => {
  //   if (viewBooth && viewBooth.id == boothId) {
  //     ReactGA.pageview(`/booths/${viewBooth.title}`)
  //     amplitude.getInstance().logEvent(`Visit Booth`,{
  //       boothName: viewBooth.title
  //     });
  //   }
  // }, [currentUser.id, viewBooth])

  useEffect(() => {
    dispatch(getBooth(boothId))

    const halls = hallA.concat(hallB)

    setBooth((halls.filter(booth=>booth.id === boothId))[0])
  }, [boothId])

  const getHallName = () => {
    return `Hall ${booth.hallName}`;
  }

  const getBackLink = () => {
    return booth.hallName === 'A' ? routes.exhibition1 : routes.exhibition2;
  }

  const goNext = () => {
    const nextLink = `/booths/${booth.nextId}`;
    history.push(nextLink);
  }

  const goPrevious = () => {
    const prevLink = `/booths/${booth.prevId}`;
    history.push(prevLink);
  }

  const toggleVideoModal = () => setVideoModal(!videoModal)
  const togglePdfModal = () => setPdfModal(!pdfModal)
  const toggleImageModal = () => {
    // amplitude.getInstance().logEvent('Click Booth HotButton', {
    //   boothName: viewBooth.title,
    //   hotButtonType: 'poster',
    // });
    setImageModal(!imageModal)
  }

  const toggleInfoModal = () => {
    // amplitude.getInstance().logEvent('Click Booth HotButton', {
    //   boothName: viewBooth.title,
    //   hotButtonType: 'info',
    // });
    setInfoModal(!infoModal)
  }
  const toggleIframeModal = () => {
    setVideoLink(null);
    setVideoLoading(true);
    setIframeModal(!iframeModal);
  }

  const onPlayVideo = (link) => {
    setVideoLink(link);
    setVideoModal(false);
    setIframeModal(true);
  }

  const handleTawk = () => {
    // amplitude.getInstance().logEvent('Click Booth HotButton', {
    //   boothName: viewBooth.title,
    //   hotButtonType: 'chat',
    // });
    loadTawk(
      currentUser,
      // 'https://embed.tawk.to/5fcf0f5a920fc91564ce7be4/1ep30671j',
      viewBooth.chat_link,
      'testtesttest'
    )
    setShowTawk(!showTawk);
  };

  const handleVideoClick = () => {
    // amplitude.getInstance().logEvent('Click Booth HotButton', {
    //   boothName: viewBooth.title,
    //   hotButtonType: 'video',
    // });
    if (viewBooth.video_urls.length !== 1) {
      setVideoModal(true);
    } else {
      if (boothId === '22') {
        setVideoLink(`${viewBooth.video_urls[0]}${window.location.hostname}`);
      } else {
        setVideoLink(viewBooth.video_urls[0]);
      }
      setIframeModal(true);
    }

    // if (boothId === '22') {
    //   const newPageUrl = 'https://www.twitch.tv/hirwandzahir';
    //   window.open(newPageUrl, "_blank");
    // } else if (viewBooth.video_urls.length !== 1) {
    //   setVideoModal(true);
    // } else {
    //   setVideoLink(viewBooth.video_urls[0]);
    //   setIframeModal(true);
    // }
  }
  const handleConsultation = () => {
    // amplitude.getInstance().logEvent('Click Booth HotButton', {
    //   boothName: viewBooth.title,
    //   hotButtonType: 'consultation',
    // });
    window.open(
      "https://jiggee.zoom.us/my/hybridhub3", 
      '_blank'
    )
  }

  const handlePdfClick = () => {
    // amplitude.getInstance().logEvent('Click Booth HotButton', {
    //   boothName: viewBooth.title,
    //   hotButtonType: 'pdf',
    // });
    if (boothId === '13') {
      const newPageUrl = 'https://pages.awscloud.com/APAC-public-DL-eBook-Download-Machine-Learning-for-Government-Education-Healthcare-and-Nonprofits-2020-learn.html?sc_channel=em&sc_campaign=%20APAC_WWPS_LN_ebook-download-machine-learning-for-government-education-healthcare-and-nonprofits_2020106_7010z000001M0mW&sc_medium=em_&sc_content=REG_webinar_wwps&sc_detail=ebookdownloadmachinelearning&sc_geo=apac&sc_country=mult&sc_outcome=reg&trk=lp_apac_crosspromo_EPFMY';
      window.open(newPageUrl, "_blank");
    } else {
      setPdfModal(true);
    }
  }

  if (!booth) {
    return (
      <div>
        <Page>
          <LoadIcon2 />
        </Page>
      </div>
    )
  }

  return (
    <div>
      <Page>

        <div className='outer-wrapper'>

          {
            iframeModal &&
            <div className='modal-container'>
              <p className='modal-container-close' onClick={toggleIframeModal}>Close</p>
              <div className='modal-container-content'>
                <div className='iframe-container'>
                  { videoLoading && <LoadIcon/> }
                  {
                    videoLink &&
                    <iframe
                      title={videoLink}
                      src={`${videoLink}?autoplay=1`}
                      allow="autoplay; fullscreen; encrypted-media"
                      allowFullScreen
                      allowtransparency="true"
                      frameBorder="0"
                      onLoad={() => {
                        setVideoLoading(false)
                      }}
                    />
                  }
                </div>
              </div>
            </div>
          }

          {
            videoModal &&
            <div className='modal-container'>
              <p className='modal-container-close' onClick={toggleVideoModal}>Close</p>
              <div className='modal-container-content'>
                <p className='modal-content-title'>Select video to play</p>
                {
                  viewBooth.video_urls.map((item, index) => (
                    <div className='modal-content-row' key={index}>
                      <p className='modal-content-label'>Video {index + 1}</p>
                      {/* <a href={item} target='_blank' rel='noopener noreferrer' className='modal-content-button'>Play</a> */}
                      <button onClick={() => onPlayVideo(item)} className='modal-content-button'>Play</button>
                    </div>
                  ))
                }
              </div>
            </div>
          }

          {
            pdfModal &&
            <div className='modal-container'>
              <p className='modal-container-close' onClick={togglePdfModal}>Close</p>
              <div className='modal-container-content'>
                <p className='modal-content-title'>Select file to download</p>
                {
                  viewBooth.documents.map((item, index) => (
                    <div className='modal-content-row' key={index}>
                      <p className='modal-content-label'>{item.filename}</p>
                      <a href={item.url} target='_blank' rel='noopener noreferrer' className='modal-content-button'>Download</a>
                    </div>
                  ))
                }
              </div>
            </div>
          }

          {
            imageModal &&
            <div className='modal-container'>
              <p className='modal-container-close' onClick={toggleImageModal}>Close</p>
              <div className='modal-container-content'>
                <Carousel>
                  {
                    viewBooth.images.map((item, index) => (
                      <Carousel.Item key={index}>
                        <img src={item.url} className='d-block w-100'/>
                      </Carousel.Item>
                    ))
                  }
                </Carousel>
              </div>
            </div>
          }

          {
            infoModal &&
            <div className='modal-container'>
              <p className='modal-container-close' onClick={toggleInfoModal}>Close</p>
              <div className='modal-container-content' dangerouslySetInnerHTML={ {__html: `${viewBooth.content}`}}>
              </div>
            </div>
          }


          <div className="container">
            <div className="hall-info">
              <Link to={getBackLink()}>Back to hall</Link>
            </div>
          </div>

          <div className="container">
            <div className="nav-wrapper">
              <p className="booth-name">{booth.name}</p>
              <div className="nav-buttons-wrapper">
                {
                  booth.prevId && (
                    <img
                      src={chevronLeft}
                      className="nav-button-icon"
                      onClick={goPrevious}
                    />
                  )
                }
                {
                  booth.nextId && (
                    <img
                      src={chevronRight}
                      className="nav-button-icon"
                      onClick={goNext}
                    />
                  )
                }
              </div>
            </div>
          </div>

          <div className="booth-image-wrapper">
            <img src={booth.bgImg} className="booth-image" />
          </div>

          {
            viewBooth ?
            <div className="container">
              <div className="booth-menu-list">
                <div className="booth-menu-item" onClick={handleVideoClick}>
                  <img src={play} className="booth-menu-item-icon"/>
                  <p className="booth-menu-item-label">Video Gallery</p>
                </div>

                <div className="booth-menu-item" onClick={handlePdfClick}>
                  <img src={pdf} className="booth-menu-item-icon"/>
                  <p className="booth-menu-item-label">PDF Gallery</p>
                </div>

                <div className="booth-menu-item" onClick={toggleImageModal}>
                  <img src={image} className="booth-menu-item-icon"/>
                  <p className="booth-menu-item-label">Image Gallery</p>
                </div>

                <div className="booth-menu-item" onClick={toggleInfoModal}>
                  <img src={info} className="booth-menu-item-icon"/>
                  <p className="booth-menu-item-label">Exhibitor Info</p>
                </div>

                <div
                  className="booth-menu-item"
                  onClick={handleTawk}
                >
                  <img src={message} className="booth-menu-item-icon" />
                  <p className="booth-menu-item-label">Talk To Us</p>
                </div>
              </div>

              <div className="booth-info-wrapper" dangerouslySetInnerHTML={ {__html: `${viewBooth.content}`}}>
              </div>
              {
                viewBooth.id === 5 &&
                <div className="booth-info-wrapper">
                  <p>Consultation Session</p>
                  <a href="#" onClick={handleConsultation}>
                    https://jiggee.zoom.us/my/hybridhub3
                  </a>
                </div>
              }


              <p className="booth-contact-info-title">Contact Information:</p>

              {
                viewBooth.booth_agents.map((item, index) => (
                  <div className="booth-contact-item-wrapper" key={index}>
                    <div className="booth-contact-item-info">
                      {
                        item.name &&
                        <p>
                          <b>{item.name}</b>
                        </p>
                      }
                      { item.phone && <p>{item.phone}</p> }
                      { item.email && <p>{item.email}</p> }
                    </div>
                    <img src={whatsapp} className="booth-contact-item-icon" />
                  </div>
                ))
              }

            </div> :
            <div className='load-container'>
              <LoadIcon2 />
            </div>
          }

          {
            showTawk &&
            <div className='tawk-modal-container'>
              <p className='modal-container-close' onClick={() => { setShowTawk(!showTawk) }}>Close</p>
              <div className='tawk-modal-container-content'>
                <LoadIcon2 />
                <div id='testtesttest'></div>
              </div>
            </div>
          }

        </div>

      </Page>
    </div>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .container {
    width: 90%;
    margin: auto;
  }

  .hall-info {
    font-size: 12px;
    margin: 20px 0px 20px 0px;
    color: black;

    a {
      color: black;
    }
  }

  .nav-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /*background: orange;*/
    margin-bottom: 20px;
  }

  .booth-name {
    width: 70%;
    font-size: 16px;
    margin: 0;
  }

  .nav-buttons-wrapper {
    width: 50px;
    height: 20px;
    /*background: green;*/
    display: flex;
    justify-content: space-between;
  }

  .nav-button-icon {
    height: 20px;
  }

  .booth-image-wrapper {
    width: 100%;
    height: 200px;
    background: lightgrey;
  }

  .booth-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .booth-menu-list {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px 20px 0px;
  }

  .booth-menu-item {
    height: 60px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .booth-menu-item-icon {
    height: 25px;
  }

  .booth-menu-item-label {
    font-size: 11px;
    color: #492379;
    margin: 0;
    text-align: center;
  }

  .booth-info-wrapper {
    padding: 20px 0px 20px 0px;
    font-size: 12px;
    border-top: solid 3px #c69dff;
    border-bottom: solid 3px #c69dff;
    margin-bottom: 20px;
    width: 100%;
    overflow-x: scroll;

    p {
      margin-bottom: 0;
      width: 100%;
    }
  }

  .booth-contact-info-title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .booth-contact-item-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .booth-contact-item-info {
    color: #8669b1;
    font-size: 14px;

    p {
      margin-bottom: 0;
    }
  }

  .booth-contact-item-icon {
    height: 25px;
  }

  .load-container {
    width: 100%;
    height: 100px;
    position: relative;
  }

  .modal-container {
    width: 95%;
    height: 60%;
    border-radius: 30px;
    border: solid 2px #5c628a;
    position: fixed;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    z-index: 99;
  }

  .modal-container-close {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-bottom: 0;
    position: absolute;
    top: 20px;
    right: 30px;
  }

  .modal-container-content {
    width: calc(100% - 60px);
    height: calc(100% - 70px);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
    font-size: 12px;
  }

  .modal-content-title {
    font-size: 16px;
    font-style: italic;
    color: #5461a2;
  }

  .modal-content-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .modal-content-label {
    width: 70%;
    margin-bottom: 0;
    margin: 0;
    font-size: 14px;
  }

  .modal-content-button {
    background: #5461a2;
    color: white;
    font-size: 10px;
    border: none;
    border-radius: 20px;
    padding: 5px 20px;
  }

  .modal-content-text {
    font-size: 12px;
  }

  #testtesttest {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .tawk-modal-container {
    width: 100%;
    height: 80%;
    border-radius: 30px;
    border: solid 2px #5c628a;
    position: fixed;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    z-index: 99;
  }

  .tawk-modal-container-content {
    width: 100%;
    height: calc(100% - 70px);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
    font-size: 12px;
  }

  .iframe-container {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    background: black;
    position: absolute;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }

    .booth-image-wrapper {
      height: 300px;
    }

    .modal-container {
      max-width: calc(0.95 * 540px);
    }

    .tawk-modal-container {
      max-width: 540px;
    }
  }
`

export default MobileExpoBooth
