import React from 'react';
import styled from 'styled-components';

const FormWrapper = styled.div`
  width: 280px;
  height: 300px;
  position: absolute;
  /*top: 35%;*/
  top: 45%;
  left: 50%;
  transform: translateX(-50%);

  input {
    width: 100%;
    background: white;
    padding: 15px 30px 15px 30px;
    border: none;
    border-radius: 100px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .button {
    width: 100%;
    background: #fab700;
    padding: 15px 30px 15px 30px;
    border: none;
    border-radius: 100px;
    color: white;
    font-style: italic;
    /*display: flex;*/
    /*justify-content: space-between;*/
  }

  .button:hover {
    cursor: pointer;
  }

  .button:disabled {
    opacity: 0.5;
  }

  .form-link {
    display: block;
    text-align: center;
    color: white;
    font-style: italic;
    font-size: 12px;
    margin-top: 20px;
  }

`;

export default FormWrapper;