import axios from 'axios'
import config from '../config'
import { getButtonPos, getPrevNext } from '../utils/boothHelper'

const SEND_REQUEST = 'SEND_REQUEST'
const RECEIVE_HALLS = 'RECEIVE_HALLS'
const RECEIVE_HALLDATA = 'RECEIVE_HALLDATA'
const RECEIVE_BOOTH = 'RECEIVE_BOOTH'

const initialState = {
  halls: [],
  hallData: {},
  viewBooth: null,
}

export default function boothReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_REQUEST:
      return {
        ...state,
        viewBooth: null,
      }
    case RECEIVE_HALLS:
      return {
        ...state,
        halls: action.halls
      }

    case RECEIVE_HALLDATA:
      return {
        ...state,
        hallData: action.hallData
      }

    case RECEIVE_BOOTH:
      return {
        ...state,
        viewBooth: action.viewBooth
      }

    default:
      return state
  }
}

export function getHalls() {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/v1/halls`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveHalls(data.halls))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getHallData(hallId) {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/v1/halls/${hallId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveHallData(data.hallData))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getBooth(boothId) {
  return (dispatch) => {
    dispatch(requestData())

    return axios
      .get(`${config.apiUrl}/api/v1/booths/${boothId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveBooth({
            ...data.booth,
            btnPos: getButtonPos(data.booth.booth_type),
            navigate: getPrevNext(data.booth.id)
          }))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

function requestData() {
  return {
    type: SEND_REQUEST,
  }
}

function receiveHalls(halls) {
  return {
    type: RECEIVE_HALLS,
    halls,
  }
}

function receiveHallData(hallData) {
  return {
    type: RECEIVE_HALLDATA,
    hallData,
  }
}

function receiveBooth(viewBooth) {
  return {
    type: RECEIVE_BOOTH,
    viewBooth,
  }
}