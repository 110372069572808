import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js';

import videosAndPodcasts from '../../images/mobile/videos-and-podcasts.png'
import infographics from '../../images/mobile/infographics.png'
import virtualGallery from '../../images/mobile/virtual-gallery.png'
import magazines from '../../images/mobile/magazines.png'

import routes from '../../routes'

const MobileGalleriumHome = ({ currentUser }) => {
  // useEffect(() => {
  //   ReactGA.pageview(`/gallerium`)
  //   amplitude.getInstance().logEvent(`Visit Gallerium`);
  // }, [currentUser.id])

  return (
    <div>
      <Page>
        <div className="outer-wrapper">
          <div className="container">
            <div className="title-container">
              <p className="title">Gallerium</p>
              <Link className="back-button" to={routes.lobby}>
                Back
              </Link>
            </div>

            <div className="item-row">
              <div className="item-container">
                <div className="item-icon-wrapper">
                  <img src={infographics} className="infographics" />
                </div>
                <p className="item-label">Infographics</p>
                <Link to="/gallerium/infographics" className="item-button">
                  View
                </Link>
                {/* <button type='button' className='item-button' disabled>View</button> */}
              </div>

              <div className="item-container">
                <div className="item-icon-wrapper">
                  <img
                    src={videosAndPodcasts}
                    className="videos-and-podcasts"
                  />
                </div>
                <p className="item-label">Videos & Podcast</p>
                <Link to="/gallerium/videos-podcast" className="item-button">
                  View
                </Link>
                {/* <button type='button' className='item-button' disabled>View</button> */}
              </div>
            </div>

            <div className="item-row">
              <div className="item-container">
                <div className="item-icon-wrapper">
                  <img src={magazines} className="magazines" />
                </div>
                <p className="item-label">Magazines</p>
                <Link to="/gallerium/magazines" className="item-button">
                  View
                </Link>
              </div>

              <div className="item-container">
                <div className="item-icon-wrapper">
                  <img src={virtualGallery} className="virtual-gallery" />
                </div>
                <p className="item-label">Virtual Gallery</p>
                <Link to="/gallerium/virtual-gallery" className="item-button">
                  View
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </div>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 30px 0px 10px 0px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    width: 80%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-top: 10px;
  }

  .item-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  .item-container {
    display: flex;
    width: 140px;
    height: 195px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    background: white;
    border: solid 3px #720105;
    border-radius: 20px;
  }

  .item-icon-wrapper {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-label {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #272727;
    margin: 0px;
    width: 90px;
  }

  .item-button {
    background-color: #270949;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: bold;
    width: 90px;
    height: 30px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-button:disabled {
    opacity: 0.5;
  }

  .videos-and-podcasts {
    width: 160%;
    transform: translateY(-2%);
  }

  .infographics {
    width: 160%;
    transform: translateX(5%);
  }

  .virtual-gallery {
    width: 160%;
  }

  .magazines {
    width: 160%;
    transform: translateX(5%);
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileGalleriumHome
