import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js';

import materials from '../../constants/galleriumMaterials';

const MobileGalleriumInfographicsItem = ({ currentUser }) => {

  const { name } = useParams();
  const [zoomIndex, setZoomIndex] = useState(-1);

  // useEffect(() => {
  //   ReactGA.pageview(`/gallerium/infographics/${name}`)
  //   amplitude.getInstance().logEvent(`Visit Gallerium-infographics-${name}`);
  // }, [currentUser.id])

  // console.log(materials[0].materials.filter((item) => item.name === name)[0]);

  return (
    <div>
      <Page>

        <div className='outer-wrapper'>

          <div className='container'>

            <div className='title-container'>
              <p className='title'>{name}</p>
              <Link className='back-button' to='/gallerium/infographics'>Back</Link>
            </div>

            {
              zoomIndex > -1 ?
              <img
                src={materials[0].materials.filter((item) => item.name === name)[0].infographics[zoomIndex]}
                className='image-zoom'
                onClick={() => setZoomIndex(-1) }
              /> :
              <div className='list-container'>
                {
                  materials[0].materials.filter((item) => item.name === name)[0].infographics.map((item, index) => (
                    <div className='list-item' key={index}>
                      <img
                        src={item}
                        className='list-image'
                        onClick={() => setZoomIndex(index) }
                      />
                    </div>
                  ))
                }
              </div>
            }
            
          </div>

        </div>

      </Page>
    </div>
  );
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 30px 0px 10px 0px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    width: 80%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-top: 10px;
  }

  .list-container {
    width: 100%;
    column-count: 2;
  }

  .list-item {
    width: 100%;
  }

  .list-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .image-zoom {
    width: 100%;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }

`;

export default MobileGalleriumInfographicsItem;