import React from 'react'
import styled from 'styled-components'
import amplitude from 'amplitude-js';
import { Nav, Navbar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { COLORS } from '../styles/theme'
import routes from '../routes'
import { logoutUser } from '../ducks/auth'
import { showGlobalModal, toggleMute } from '../ducks/layout'

import listIcon from '../images/navigation/listIcon.png'
import lobbyIcon from '../images/navigation/LobbyIcon.png'
import unmuteIcon from '../images/navigation/unmute.png'
import exitIcon from '../images/navigation/exit.png'
import notificationIcon from '../images/navigation/Notification.png'

const StyledNavbar = styled(Navbar)`
  background: ${COLORS.nav};
  width: 180px;
  position: fixed;
  top: 15vh;
  right: -110px;
  z-index: 50;
  border-bottom-left-radius: 28px;
  border-top-left-radius: 28px;
  transition: 1s;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  .nav-label {
    font-size: 13px;
    text-align: left;
    width: 60%;
    display: block;
    color: #492777;
    align-self: center;
  }
  &:hover {
    right: 0;
  }
  .nav-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    svg,
    span {
      display: block;
    }
  }
`

export default function TopNav() {
  const dispatch = useDispatch()
  const { muted } = useSelector((state) => state.layout)

  const displayProgramme = () => {
    amplitude.getInstance().logEvent('Click Menu');
    dispatch(showGlobalModal('programme'))
  }

  const displayNotifications = () => {
    amplitude.getInstance().logEvent('Click Notifications');
    dispatch(showGlobalModal('notifications'))
  }

  const toggleAudio = () => {
    amplitude.getInstance().logEvent('Click Mute button');
    dispatch(toggleMute())
  }

  const handleLogout = () => {
    amplitude.getInstance().logEvent('Click Logout');
    dispatch(logoutUser())
  }

  return (
    <StyledNavbar>
      <Nav.Link as={Link} to={routes.lobby}>
        <img src={lobbyIcon} width="26" height="26" alt="Nav" />
        <span className="nav-label">Lobby</span>
      </Nav.Link>
      <Nav.Link onClick={displayProgramme} key="schedule">
        <img src={listIcon} width="26" height="26" alt="Nav" />
        <span className="nav-label">Menu</span>
      </Nav.Link>
      <Nav.Link onClick={displayNotifications} key="notifications">
        <img src={notificationIcon} width="26" height="26" alt="Nav" />
        <span className="nav-label">Notifications</span>
      </Nav.Link>
      {/* <Nav.Link onClick={toggleAudio} key="audio">
        <img src={unmuteIcon} width="26" height="26" alt="Nav" />
        <span className="nav-label">{muted ? 'Unmute' : 'Mute'}</span>
      </Nav.Link> */}
      <Nav.Link onClick={handleLogout}>
        <img src={exitIcon} width="26" height="26" alt="Nav" />
        <span className="nav-label">Logout</span>
      </Nav.Link>
    </StyledNavbar>
  )
}
