import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js';
import config from '../config'
import { SIZES } from '../styles/theme'
import { Link, useParams } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Tabs, Tab } from 'react-bootstrap'

import kwspLogo from '../images/kwsp_logo.png'
import kfairLogo from '../images/logo.png'
import title from '../images/icons/title.png'
import PageWithBg from '../components/PageWithBg'
import backIcon from '../images/icons/back.png'
import materials from '../constants/galleriumMaterials'
import InfographicModal from '../components/InfograpicModal'
import LoadIcon from '../components/LoadIcon'

const Page = styled.div`

  .full {
    position: absolute;
    padding-top: ${SIZES.navHeight};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;

    .sidenavs {
      height: 100%;
      width: 25%;
      display: flex;
      flex-direction: column;
      text-align: center;
      color: white;
    }
    .main {
      width: 75%;
      /* backdrop-filter: blur(15px); */
      background-color: rgba(255,255,255,0.7);
      padding: 32px 56px;
      padding-right: 72px;
      height: 100%;
      overflow-y: hidden;
      .main-content {
        height: 100%;
        h5.category {
          margin-top: 32px;
          margin-bottom: -20px;
        }
        .tab-content {
          height: 75%;
        }
      }
      .listing {
        margin-top: 36px;
        height: 75%;
        .infographic {
          background: white;
          width: 175px;
          height: 175px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin-right: 16px;
          margin-bottom: 32px;
          cursor: pointer;
        }
      }
      .vid-tabs {
        justify-content: flex-end;
        position: relative;
        .nav-item {
          background-color: transparent;
          color: #71030B;
          border: none;
          font-weight: 500;
          padding-top: 0;
        }
        .nav-item.active {
          color: #E20613;
          font-style: italic;
          text-decoration: underline;
        }
        .tab-pane {
          height: 100%;
        }
      }
      .toggle-back {
        font-weight: bold;
        color: #171717;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        text-decoration: none;
        .back-prev {
          display: flex;
          width: 30px;
          flex-direction: column;
          transition: 1s;
          img {
            width: 26px;
          }
          .icon-label {
            text-align: left;
            font-size: 1px;
            opacity: 0;
          }
          &:hover {
            img {
              transform: scale(0.6);
            }
            .icon-label {
              font-size: 8px;
              opacity: 1;
            }
          }
        }
        span {
          font-size: 24px;
          font-style: italic;
          margin-left: 1em;
        }
      }
    }
  }
`

export default function SubSectionPage({ currentUser }) {
  const { category, name } = useParams()
  const [ gallery, setGallery ] = useState(null)
  const [ posterOpen, setPosterOpen ] = useState(false)
  const [ content, setContent ] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let filtered = materials.filter(mat => mat.destination === `/gallerium/${category}`)
    let selectedName = filtered[0].materials.filter(group=> group.name === name)
    setGallery(selectedName[0])
  }, [category, name])
  
  // useEffect(() => {
  //   ReactGA.pageview(`/gallerium/${category}/${name}`)
  //   amplitude.getInstance().logEvent(`Visit Gallerium-${category}-${name}`);
  // }, [currentUser.id])

  const hidePoster = () => setPosterOpen(false)

  const showPoster = (image) => {
    setContent(image)
    setPosterOpen(true)
  }
  return (
    <Page>
      <PageWithBg
        bgImg={config.assets.gallerium}
      >
        {
          gallery && 
          <section className="full">
            <div className="sidenavs">
              <img src={kwspLogo} className="d-block mx-auto mt-5 mb-3" width="40%" alt="kwsp logo" />
              <img src={kfairLogo} className="d-block mx-auto my-3" width="40%" alt="kfair logo" />
              <img src={title} width="60%" className="d-block mx-auto my-4" alt="slogan" />
              <img src={gallery.image_trans ? gallery.image_trans : gallery.image } className="d-block mx-auto mt-3" width="60%" alt="gallery icon" />
            </div>
            <div className="main">
              <Link to={`/gallerium/${category}`} className="toggle-back">
                <div className="back-prev">
                  <img src={backIcon} alt="back-icon" />
                  <small className="icon-label">Back</small>
                </div>
                <span>{gallery.name}</span>
              </Link>
              <div className="main-content">
                { 
                  category === 'infographics' ? 
                  <div className="d-flex flex-wrap listing">
                    {gallery.infographics.map((item, index)=>(
                      <LazyLoadComponent key={`${name}-${index}`}>
                        <div 
                          className="infographic"
                          onClick={()=>showPoster(item)}
                        >
                          <img src={item} width="100%" alt={`${name}-${index}`}/>
                        </div>
                      </LazyLoadComponent>
                    ))}
                  </div>
                  :
                  <>
                    <h5 className="category">{name}</h5>
                    <Tabs className="vid-tabs" defaultActiveKey="link0">
                      { 
                        gallery.video.map((vid, index)=>(
                          <Tab eventKey={`link${index}`} key={`${name}-${index}`} title={`Video ${index+1}`}>
                            <div className="video-container" style={{position:'relative',paddingBottom:'56.25%',height:0,overflow:'hidden'}}>
                              {loading && <LoadIcon />}
                              <iframe
                                style={{position:'absolute',top:0,left:0}}
                                width="100%"
                                height="100%"
                                title="Event Trailer"
                                src={vid.link}
                                allowFullScreen
                                allow="autoplay; fullscreen"
                                frameBorder="0"
                                onLoad={() => {
                                  setLoading(false)
                                }}
                              />
                            </div>
                          </Tab>
                        ))
                      }
                    </Tabs>
                  </>
                }
              </div>
            </div>
          </section>
        }
      </PageWithBg>
      <InfographicModal posterOpen={posterOpen} hidePoster={hidePoster} content={content} />
    </Page>
  )
}
