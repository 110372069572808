import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js';
import config from '../config'
import routes from '../routes'
import { COLORS, SIZES } from '../styles/theme'
import { Link, useParams, useLocation } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import kwspLogo from '../images/kwsp_logo.png'
import kfairLogo from '../images/logo.png'
import title from '../images/icons/title.png'
import PageWithBg from '../components/PageWithBg'
import backIcon from '../images/icons/back.png'
import playIcon from '../images/icons/play_circle.png'
import materials from '../constants/galleriumMaterials'
import IframeModal from '../components/GlobalModals/IframeModal'

const Page = styled.div`

  .full {
    position: absolute;
    padding-top: ${SIZES.navHeight};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;

    .sidenavs {
      height: 100%;
      width: 25%;
      display: flex;
      flex-direction: column;
      text-align: center;
      color: white;
    }
    .main {
      width: 75%;
      /* backdrop-filter: blur(15px); */
      background-color: rgba(255,255,255,0.7);
      padding: 32px 56px;
      padding-right: 72px;
      height: 100%;
      overflow-y: scroll;

      .listing {
        margin-top: 36px;

        .card-thumbnail {
          background: white;
          width: 175px;
          height: 175px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;
          margin-bottom: 32px;
          cursor: pointer;
          position: relative;
          &:hover {
            .play-icon {
              transition: 200ms;
              transform: scale(1.2);
            }
          }
          .play-icon {
            position: absolute;
          }
        }
        .podcast-container {
          cursor: pointer;
          position: relative;
          background: white;
          width: 175px;
          height: 175px;
          margin-right: 16px;
          margin-bottom: 32px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          p.podcast-title {
            min-height: 48px;
            text-align: center;
            width: 100%;
            color: #BBBDBF;
            background: #492777;
            margin: 0;
            font-size: 12px;
            padding: 4px 2px;
          }
          &:hover {
            img {
              transition: 200ms;
              transform: scale(1.2);
            }
          }
        }
        .mag {
          align-items: flex-start;
        }
        .virtual-thumbnail {
          cursor: pointer;
          width: 100%;
          height: 400px;
          margin-bottom: 32px;
          position: relative;
          .banner {
            color: white;
            position: absolute;
            width: 100%;
            text-align: center;
            letter-spacing: 1px;
            bottom: 0;
            background: ${COLORS.primary};
          }
        }
      }
      .toggle-back {
        font-weight: bold;
        color: #171717;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        text-decoration: none;
        .back-prev {
          display: flex;
          width: 30px;
          flex-direction: column;
          transition: 1s;
          img {
            width: 26px;
          }
          .icon-label {
            text-align: left;
            font-size: 1px;
            opacity: 0;
          }
          &:hover {
            img {
              transform: scale(0.6);
            }
            .icon-label {
              font-size: 8px;
              opacity: 1;
            }
          }
        }
        
        span {
          font-size: 24px;
          font-style: italic;
          margin-left: 1em;
        }
      }
    }
  }
`

export default function SubGalleriumPage({ currentUser }) {
  const { category } = useParams()
  const location = useLocation()
  const [ gallery, setGallery ] = useState(null)
  const [ podcast, setPodcast ] = useState(null)
  const [ podcastOpen, setPodcastOpen ] = useState(false)

  useEffect(() => {
    let filtered = materials.filter(mat => mat.destination === location.pathname)
    setGallery(filtered[0])
  }, [category, location.pathname])
  
  // useEffect(() => {
  //   ReactGA.pageview(`/gallerium/${category}`)
  //   amplitude.getInstance().logEvent(`Visit Gallerium-${category}`);
  // }, [currentUser.id])

  const showPodcast = (pod) => {
    setPodcast(pod)
    setPodcastOpen(true)
    // amplitude.getInstance().logEvent(`Click podcast: ${pod.title}`);
  }
  return (
    <Page>
      <PageWithBg
        bgImg={config.assets.gallerium}
      >
        {
          gallery && 
          <section className="full">
            <div className="sidenavs">
              <img src={kwspLogo} className="d-block mx-auto mt-5 mb-3" width="40%" alt="kwsp logo" />
              <img src={kfairLogo} className="d-block mx-auto my-3" width="40%" alt="kfair logo" />
              <img src={title} width="60%" className="d-block mx-auto my-4" alt="slogan" />
              <img src={gallery.icon} className="d-block mx-auto" width="50%" alt="gallery icon" />
              <h5>{gallery.name}</h5>
            </div>
            <div className="main">
              <Link to={routes.gallerium} className="toggle-back">
                <div className="back-prev">
                  <img src={backIcon} alt="back-icon" />
                  <small className="icon-label">Back</small>
                </div>
                <span>{gallery.name}</span>
              </Link>
              <div className="d-flex flex-wrap listing">
                {
                  gallery.materials.map(item=>(
                    item.link ?  
                    <LazyLoadComponent key={item.name}>
                      <a 
                        href={item.link} 
                        className={category === "magazines" ? "card-thumbnail mag" : "virtual-thumbnail"}
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{background:`url('${item.image}')`,backgroundSize:'cover'}}
                      >
                        <div className={category === "magazine" ? "d-none" : "banner"}>{item.name}</div>
                      </a>
                    </LazyLoadComponent>
                    :
                    <LazyLoadComponent key={item.name}>
                      <Link 
                        to={`/gallerium/${category}/${item.name}`} 
                        className="card-thumbnail"
                      >
                        <img src={item.image} width="100%" alt={item.name} />
                        <img className={category === "infographics" ? "d-none" : "play-icon"} src={playIcon} width="25%" alt="play icon" />
                      </Link>
                    </LazyLoadComponent>
                  ))
                }
                {
                  category === "videos-podcast" &&
                  (
                    gallery.podcast.map(pod=>(
                      <div className="podcast-container" onClick={()=>showPodcast(pod)} >
                        <img src={gallery.icon} className="my-auto" width="50%" alt="gallery icon" />
                        <p className="podcast-title">{pod.title}</p>
                      </div>
                    ))
                  )
                }
                { 
                  podcast && 
                  <IframeModal iframeOpen={podcastOpen} setIframeOpen={setPodcastOpen} iframe={podcast} />
                }
              </div>
            </div>
          </section>
        }
      </PageWithBg>
    </Page>
  )
}
