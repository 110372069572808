import Axios from 'axios'
import React, { useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import config from '../../config'
import { COLORS } from '../../styles/theme'
import amplitude from 'amplitude-js';

const CustomModal = styled(Modal)`
  .modal-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: #C5A0FC;

    .modal-title {
      font-size: 1.2em;
    }
  }

  .btn {
    display: block;
    margin-left: auto;
    border: none;
    border-radius: 32px;
    background: ${COLORS.primaryLight};
  }

  textarea::placeholder {
    font-style: italic;
  }
`

export default function DropNamecardModal({ currentUser, user, modal, hideModal }) {
  const [message, setMessage] = useState('')
  const [load, setLoad] = useState(false)

  const handleInput = (e) => setMessage(e.target.value)

  const submitNamecard = () => {
    // setLoad(true)
    // amplitude.getInstance().logEvent('Drop name card', {
    //   SenderID: currentUser.id,
    //   SenderFullName: currentUser.fullName,
    //   SenderEmail: currentUser.email,
    //   ReceiverFullName: user.fullName,
    //   ReceiverEmail: user.email,
    // });
    toast(`An email has been sent to ${user.fullName}!`)
    hideModal(0)
    setMessage('')
    // Axios({
    //   method: 'post',
    //   url: `${config.apiUrl}/api/v1/drop_namecard`,
    //   data: {
    //     receiver_email: user.email,
    //     message,
    //   },
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
    //   },
    // })
    //   .then(() => {
    //     toast(`An email has been sent to ${user.fullName}!`)
    //   })
    //   .catch((err) => {
    //     setLoad(false)
    //     setMessage('')
    //     toast('Sending name card failed. Something went wrong.');
    //   })
    //   .finally(() => {
    //     setLoad(false)
    //     hideModal(0)
    //     setMessage('')
    //   })
  }

  return (
    <CustomModal
      show={modal}
      onHide={hideModal}
      aria-labelledby="drop-name-card"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="drop-name-card">Drop Name Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted">
          Drop <strong>{user.fullName}</strong> your name card!
        </p>
        <p className="text-muted">
          This will send {user.fullName} an email with your contact information
          indicating that you wish to connect.
        </p>
        <p className="text-muted">You can also include custom message:</p>
        <Form.Control
          placeholder="(Optional)"
          value={message}
          onChange={handleInput}
          as="textarea"
          rows={3}
        />
        <Button disabled={load} onClick={submitNamecard} className="mt-2">
          {load ? 'Sending...' : 'Send!'}
        </Button>
      </Modal.Body>
    </CustomModal>
  )
}
