import React from 'react';
import styled from 'styled-components';

import kfair20Tagline from '../../../images/mobile/kfair20-tagline.png';
import kwspWhite from '../../../images/mobile/kwsp-white.png';
import emc from '../../../images/mobile/emc.png';

const Logo2 = () => {
  return (
    <StyledWrapper>
      {/* <StyledImgLeft src={kwspWhite}/> */}
      {/* <StyledImgRight src={kfair20}/> */}
      <img className='kwsp' src={kwspWhite}/>
      <img className='kfair20-tagline' src={kfair20Tagline}/>
      <img className='emc' src={emc}/>
    </StyledWrapper>
  );
}


const StyledWrapper = styled.div`
  width: 300px;
  height: 80px;

  position: absolute;
  /*bottom: 12%;*/
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .kwsp {
    width: 30%;
  }

  .emc {
    width: 30%;
  }

  .kfair20-tagline {
    width: 30%;
  }
`

// const StyledImgLeft = styled.img`
//   width: 45%;
// 
//   position: absolute;
//   left: 0;
//   top: 50%;
//   transform: translateY(-50%);
// `
// 
// const StyledImgRight = styled.img`
//   width: 45%;
// 
//   position: absolute;
//   right: 0;
//   top: 50%;
//   transform: translateY(-50%);
// `

export default Logo2;