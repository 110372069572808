import React, { useEffect } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js';
import GalleriumEntryCard from '../components/GalleriumEntryCard'
import config from '../config'
import { SIZES } from '../styles/theme'
import kwspLogo from '../images/kwsp_logo.png'
import kfairLogo from '../images/logo.png'
import title from '../images/icons/title.png'
import PageWithBg from '../components/PageWithBg'
import options from '../constants/galleriumMaterials'

const Page = styled.div`
  overflow: hidden;
  .gallery-entry {
    position: absolute;
    padding-top: ${SIZES.navHeight};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;

    .logos {
      width: 25%;
    }
    .entries {
      width: 45%;
    }
  }
`

export default function GalleriumPage({ currentUser }) {
  useEffect(() => {
    document.title = `${config.eventName} | Gallerium`
  }, [])

  // useEffect(() => {
  //   ReactGA.pageview(`/gallerium`)
  //   amplitude.getInstance().logEvent(`Visit Gallerium`);
  // }, [currentUser.id])

  return (
    <Page>
      <PageWithBg bgImg={config.assets.gallerium}>
        <section className="gallery-entry">
          <div className="logos my-5">
            <img
              src={kwspLogo}
              className="d-block mx-auto my-3"
              width="55%"
              alt="kwsp logo"
            />
            <img
              src={kfairLogo}
              className="d-block mx-auto my-3"
              width="55%"
              alt="kfair logo"
            />
          </div>
          <div className="entries">
            <img
              src={title}
              width="80%"
              className="d-block mx-auto my-5"
              alt="slogan"
            />
            <div className="d-flex justify-content-center">
              {options.map((page) => (
                <GalleriumEntryCard key={page.name} {...page} />
              ))}
            </div>
          </div>
        </section>
      </PageWithBg>
    </Page>
  )
}
