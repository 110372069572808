import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../styles/theme'
import backIcon from '../images/icons/backIcon.png'
import KfairAgenda from './KfairAgenda'
import McAgenda from './McAgenda'

const SideNav = styled.div`
  #sidebar {
    display: ${(props) => (props.open ? 'block' : 'none')};
    width: 400px;
    max-width: 100vw;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    background: ${(props) => (props.hallNumber === 1 ? `linear-gradient(#71030B, #270D48)` : `linear-gradient(#292D95, #0083A9)`)};
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    overflow-x: hidden;

    .close {
      position: absolute;
      z-index: 55;
      background: transparent;
      border: none;
      outline: none;
      color: white;
      transition: 200ms;
      ${SIZES.rightBelowNoticeBar(9)}
      right: 20px;
      opacity: 1;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      text-shadow: none;
    }
    .agenda {
      color: white;
      padding: 48px 32px;
    }
  }

  ${SIZES.mobile} {
    #sidebar {
      width: 100vw;
    }
  }
`
export default function SideAgenda({ open, toggleSidebar, hallNumber, currentUser, agendaList, setAgendaList, takeAttendance, isLoading }) {
  return (
    <SideNav open={open} hallNumber={hallNumber}>
      <div id="sidebar">
        <button onClick={toggleSidebar} className="close">
          {open && (
            <>
              <small>Back</small>
              <img src={backIcon} width="40" alt="back-icon"/>
            </>
          )}
        </button>
        <div className="agenda h-100">
          {
            hallNumber === 1 ?
              <KfairAgenda currentUser={currentUser} agendaList={agendaList} setAgendaList={setAgendaList} takeAttendance={takeAttendance} isLoading={isLoading} />
            :
              <McAgenda currentUser={currentUser} />
          }
        </div>
      </div>
    </SideNav>
  )
}
