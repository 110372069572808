import React from 'react';
import styled from 'styled-components';


const Message = (props) => {

  const {
    title,
    subtitle,
  } = props;

  

  return (
    <div>
      <StyledTitle>{title}</StyledTitle>
      <StyledSubtitle>{subtitle}</StyledSubtitle>
    </div>
  );
}

const StyledTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
  color: #FFAD2C;
  position: absolute;
  /*top: 18%;*/
  top: 25%;
  width: 100%;
  /*left: 50%;*/
  /*transform: translateX(-50%);*/
  text-align: center;
`

const StyledSubtitle = styled.p`
  font-size: 14px;
  color: white;
  position: absolute;
  /*top: 23%;*/
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`

export default Message;