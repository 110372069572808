import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import Media from 'react-media'

import config from './config'
import routes from './routes'
import { SIZES } from './styles/theme'
import ModalContainer from './components/GlobalModals/ModalContainer'
import EntrancePages from './containers/EntrancePages'
import PrivateRoute from './containers/PrivateRoute'
import { hallA, hallB } from './constants/exhibitionBooths'

// import CountdownPage from './pages/CountdownPage'
import SetPasswordFormPage from './pages/registration/SetPasswordFormPage'

import LobbyPage from './webOnlyPages/LobbyPage'
import AuditoriumPage from './webOnlyPages/AuditoriumPage'
import NetworkingLoungePage from './webOnlyPages/NetworkingLoungePage'
import ExhibitionPage from './webOnlyPages/ExhibitionPage'
import BoothPage from './webOnlyPages/BoothPage'
import GalleriumPage from './webOnlyPages/GalleriumPage'
import SubGalleriumPage from './webOnlyPages/SubGalleriumPage'
import SubSectionPage from './webOnlyPages/SubSectionPage'

import MobileEditProfile from './mobileOnlyPages/MobileEditProfile/index.js'
import MobileEntrance from './mobileOnlyPages/MobileEntrance/index.js'
import MobileExpoBooth from './mobileOnlyPages/MobileExpo/MobileExpoBooth.js'
import MobileExpoDirectory from './mobileOnlyPages/MobileExpo/MobileExpoDirectory.js'
import MobileGallerium from './mobileOnlyPages/MobileGallerium/index.js'
import MobileHelp from './mobileOnlyPages/MobileHelp/index.js'
import MobileKFair20 from './mobileOnlyPages/MobileKFair20/index.js'
import MobileLobby from './mobileOnlyPages/MobileLobby/index.js'
import MobileMC20 from './mobileOnlyPages/MobileMC20/index.js'
import MobileMenu from './mobileOnlyPages/MobileMenu/index.js'
import MobileNetworking from './mobileOnlyPages/MobileNetworking/index.js'
import MobileNotifications from './mobileOnlyPages/MobileNotifications/index.js'

// import MobileAuditorium from './mobileOnlyPages/MobileAuditorium'

function App() {
  const { currentUser, isAuthenticated } = useSelector((state) => state.auth)

  useEffect(() => {
    document.title = config.eventName
  }, [])

  // useEffect(() => {
  //   window.chaport.q('hideLauncher')
  // }, [])

  return useMemo(() => {
    return (
      <>
        <ModalContainer currentUser={currentUser} />
        <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
          {(matches) =>
            matches ? (
              <Switch>
                {/* Entrance */}
                <Route exact path={routes.resetPassword}>
                  <MobileEntrance />
                </Route>
                <Route exact path={routes.login}>
                  <MobileEntrance />
                </Route>

                <Route exact path={routes.root}>
                  <MobileEntrance />
                </Route>

                {/* KFair20 */}
                {/* <Route exact path={routes.auditorium1}> */}
                {/*   <MobileKFair20 /> */}
                {/* </Route> */}

                {/* MC20 */}
                {/* <Route exact path={routes.auditorium2}> */}
                {/*   <MobileMC20 /> */}
                {/* </Route> */}

                {/* Expo Hall A */}
                {/* <Route exact path={routes.exhibition1}> */}
                {/*   <MobileExpo /> */}
                {/* </Route> */}

                {/* Expo Hall B */}
                {/* <Route exact path={routes.exhibition2}> */}
                {/*   <MobileExpo /> */}
                {/* </Route> */}

                {/* Gallerium */}
                {/* <Route exact path={routes.gallerium}> */}
                {/*   <MobileGallerium /> */}
                {/* </Route> */}

                {/* Notifications */}
                {/* <Route exact path='/notifications'> */}
                {/*   <MobileNotifications /> */}
                {/* </Route> */}

                {/* Menu */}
                {/* <Route exact path='/menu'> */}
                {/*   <MobileMenu /> */}
                {/* </Route> */}

                {/* Edit profle */}
                {/* <Route exact path='/edit-profile'> */}
                {/*   <MobileEditProfile /> */}
                {/* </Route> */}

                {/* Lobby */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.lobby}
                  component={MobileLobby}
                />

                {/* Help */}
                <PrivateRoute
                  exact
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={'/help'}
                  component={MobileHelp}
                />

                {/* KFair20 */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.auditorium1}
                  component={MobileKFair20}
                />

                {/* MC20 */}
                {/* <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.auditorium2}
                  component={MobileMC20}
                /> */}

                {/* Networking Hall */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.networking}
                  component={MobileNetworking}
                />

                {/* Expo Hall A */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.exhibition1}
                  component={(props) => (
                    <MobileExpoDirectory
                      {...props}
                      hallNumber={1}
                      booths={hallA}
                    />
                  )}
                />

                {/* Expo Hall b */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.exhibition2}
                  component={(props) => (
                    <MobileExpoDirectory
                      {...props}
                      hallNumber={2}
                      booths={hallB}
                    />
                  )}
                />

                {/* Gallerium */}
                <PrivateRoute
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.gallerium}
                  component={MobileGallerium}
                />

                {/* Dynamic booth */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path="/booths/:boothId"
                  component={MobileExpoBooth}
                />

                {/* Menu */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path="/menu"
                  component={MobileMenu}
                />

                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path="/edit-profile"
                  component={MobileEditProfile}
                />

                {/* Notifications */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.notifications}
                  component={MobileNotifications}
                />

                {/* Auditorium */}
                {/* <PrivateRoute */}
                {/*   exact */}
                {/*   currentUser={currentUser} */}
                {/*   authed={isAuthenticated} */}
                {/*   path={routes.auditorium} */}
                {/*   component={MobileAuditorium} */}
                {/* /> */}
              </Switch>
            ) : (
              <Switch>
                <Route
                  exact
                  path={routes.resetPassword}
                  component={SetPasswordFormPage}
                />

                {/* Entrance / Login Pages */}
                <Route exact path={routes.login} component={EntrancePages} />
                <Route exact path={routes.root} component={EntrancePages} />

                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.lobby}
                  component={LobbyPage}
                />

                {/* Networking Hall */}
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.networking}
                  component={NetworkingLoungePage}
                />

                {/* Auditorium (Vimeo) Hall */}
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.auditorium1}
                  component={(props) => (
                    <AuditoriumPage
                      {...props}
                      hallNumber={1}
                      hallBg={config.assets.auditorium1}
                      liveUrl="https://player.vimeo.com/video/567309405?autoplay=1&loop=1"
                    />
                  )}
                />

                {/* <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.auditorium2}
                  component={(props) => (
                    <AuditoriumPage
                      {...props}
                      hallNumber={2}
                      hallBg={config.assets.auditorium2}
                      liveUrl="https://player.vimeo.com/video/490388481?autoplay=1"
                    />
                  )}
                /> */}

                {/* Exhibition Hall */}
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.exhibition1}
                  component={(props) => (
                    <ExhibitionPage
                      {...props}
                      hallNumber={1}
                      booths={hallA}
                      hallBg={config.assets.exhibition1}
                    />
                  )}
                />
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.exhibition2}
                  component={(props) => (
                    <ExhibitionPage
                      {...props}
                      hallNumber={2}
                      booths={hallB}
                      hallBg={config.assets.exhibition2}
                    />
                  )}
                />
                {/* Sub-section of gallerium category */}
                <PrivateRoute
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={`/gallerium/:category/:name`}
                  component={SubSectionPage}
                />

                {/* Sub-Gallerium */}
                <PrivateRoute
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={`/gallerium/:category`}
                  component={SubGalleriumPage}
                />

                {/* Gallerium */}
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.gallerium}
                  component={GalleriumPage}
                />

                {/* dynamic Booth */}
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path="/booths/:boothId"
                  component={BoothPage}
                />
              </Switch>
            )
          }
        </Media>
      </>
    )
  }, [currentUser, isAuthenticated])
}

export default App
