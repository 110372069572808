import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { COLORS } from '../styles/theme'

const EntryCard = styled.div`
  .hall {
    text-align: center;
    background: #fff;
    border: 3px solid #71030B;
    border-radius: 16px;
    font-size: 0.75em;
    font-weight: 600;
    padding: 1em;
    width: 125px;
    .img-container {
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .hall-name {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a,button {
      background: ${COLORS.primary};
      color: #F8F4FF;
      width: 100%;
      font-size: 1em;
      border-radius: 8px;
      padding: 2px;
      box-shadow: none;
    }
  }
`

export default function GalleriumEntryCard({
  icon,
  name,
  destination
}) {
  return (
    <EntryCard>
      <div className="hall mx-2">
        <div className="img-container">
          <img src={icon} width="100px" alt={name} />
        </div>
        <div className="hall-name">
          <p className="p-0 m-0">{name}</p>
        </div>
        <Button variant="default" as={Link} to={destination}>
          View
        </Button>
      </div>
    </EntryCard>
  )
}
