import React from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import amplitude from 'amplitude-js';
import { logoutUser } from '../ducks/auth'
import lobbyNav from '../images/mobile/lobby-nav.png'
import notificationsNav from '../images/mobile/notifications-nav.png'
import menuNav from '../images/mobile/menu-nav.png'
import help from '../images/mobile/help.png'
import routes from '../routes'
import { showGlobalModal } from '../ducks/layout'

const MobileNav = React.memo(() => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser = useSelector((state) => state.auth.currentUser)

  const handleLogout = () => {
    dispatch(logoutUser())
  }
  const handleMenu = () => {
    amplitude.getInstance().logEvent('Click Menu');
    history.push(routes.menu)
  }

  const handleNotification = () => {
    amplitude.getInstance().logEvent('Click Notifications');
    history.push(routes.notifications)
  }

  const showHelpDesk = () => {
    dispatch(showGlobalModal('message'))
    // const cpt = window.chaport
    // cpt.on('ready', function () {
    //   cpt.setVisitorData({
    //     name: currentUser.fullName,
    //     email: currentUser.email,
    //   })
    //   cpt.open()
    // })
    // cpt.on('collapsed', function () {
    //   cpt.close()
    // })
    // amplitude.getInstance().logEvent('Click Lobby Helpdesk');
  }

  return (
    <Page>
      <div className="navbar">
        <Link className="nav-item" to={routes.lobby}>
          <img src={lobbyNav} className="nav-icon" />
        </Link>
        <Link className="nav-item" onClick={handleMenu} >
          <img src={menuNav} className="nav-icon" />
        </Link>
        <Link className="nav-item" onClick={handleNotification} >
          <img src={notificationsNav} className="nav-icon" />
        </Link>
        <a className="nav-item" href="#" onClick={showHelpDesk}>
          <img src={help} className="nav-icon" />
        </a>
        {/* <div className='nav-item' onClick={handleLogout}> */}
        {/*   <img src={logoutNav} className='nav-icon'/> */}
        {/* </div> */}
      </div>
    </Page>
  )
})

const Page = styled.div`
  width: 100vw;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  background: #f8f4ff;

  .navbar {
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .nav-item {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-icon {
    height: 50%;
  }
`

export default MobileNav
