import React, { useEffect } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js';
import PageWithBg from '../components/PageWithBg'
import BoothEntry from '../components/BoothEntry'
import { Link } from 'react-router-dom'
import config from '../config'

const Page = styled.div`
  overflow: hidden;
  .next-btn {
    position: absolute;
    bottom: 8vh;
    right: 5%;
    text-decoration: none;
    background: linear-gradient(#e20613, #652781);
    color: #bbbdbf;
    border-radius: 32px;
    border: 2px solid #ffad2c;
    padding: 8px 16px;
    text-align: center;
    p {
      margin: 0 auto;
    }
    &:hover {
      box-shadow: 0px 3px 12px #ffad2c;
    }
  }
`

export default function ExhibitionPage({ hallBg, booths, hallNumber, currentUser }) {
  useEffect(() => {
    document.title = `${config.eventName} | Exhibition Hall`
  }, [])

  useEffect(() => {
    ReactGA.pageview(`/exhibition-hall-${hallNumber}`)
    amplitude.getInstance().logEvent(`Visit Exhibition Hall`, {
      hallNumber: hallNumber
    });
  }, [currentUser.id])

  return (
    <Page>
      <PageWithBg bgImg={hallBg}>
        {booths.map((booth) => (
          <BoothEntry key={booth.id} {...booth} />
        ))}
        {/* <Link
          to={`/exhibition-hall-${hallNumber === 1 ? 2 : 1}`}
          className="next-btn"
        >
          <p>
            <svg width="13px" height="22px" viewBox="0 0 13 22">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-209.000000, -3123.000000)">
                  <g transform="translate(100.000000, 3068.000000)">
                    <g transform="translate(103.000000, 54.000000)">
                      <g>
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                          fill="#BBBDBF"
                          d="M13.5,5.5 C14.6,5.5 15.5,4.6 15.5,3.5 C15.5,2.4 14.6,1.5 13.5,1.5 C12.4,1.5 11.5,2.4 11.5,3.5 C11.5,4.6 12.4,5.5 13.5,5.5 Z M9.8,8.9 L7.24,21.81 C7.11,22.42 7.59,23 8.22,23 L8.3,23 C8.77,23 9.17,22.68 9.28,22.22 L10.9,15 L13,17 L13,22 C13,22.55 13.45,23 14,23 C14.55,23 15,22.55 15,22 L15,16.36 C15,15.81 14.78,15.29 14.38,14.91 L12.9,13.5 L13.5,10.5 C14.57,11.74 16.12,12.63 17.86,12.91 C18.46,13 19,12.52 19,11.91 C19,11.42 18.64,11.01 18.15,10.93 C16.63,10.68 15.37,9.78 14.7,8.6 L13.7,7 C13.14,6.11 12.02,5.75 11.05,6.16 L7.22,7.78 C6.48,8.1 6,8.82 6,9.63 L6,12 C6,12.55 6.45,13 7,13 C7.55,13 8,12.55 8,12 L8,9.6 L9.8,8.9 Z"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </p>
          <p>To {hallNumber === 1 ? 'Hall B' : 'Hall A'}</p>
        </Link> */}
      </PageWithBg>
    </Page>
  )
}
