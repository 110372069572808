import LogoL from './images/logo.png'
import Entrance from './images/backdrops/entrance.jpg'
import LobbyL from './images/backdrops/lobby.jpg'
import HallA from './images/backdrops/exhibition.jpg'
import HallB from './images/backdrops/HallB.jpg'
import KFAIR from './images/backdrops/auditorium.jpg'
import MC from './images/backdrops/MC20.png'
import NetworkingL from './images/backdrops/networking.jpg'
import LobbyMusic from './images/audio/lobby.mpeg'
import routes from './routes'
import Gallerium from './images/backdrops/gallerium.jpg'

const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://aaviidemo.herokuapp.com',
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev
// const config = prod

const assets = {
  logo: {
    landscape: LogoL,
    portrait: LogoL,
  },
  entrance: {
    landscape: Entrance,
    portrait: Entrance,
  },
  lobby: {
    landscape: LobbyL,
    portrait: LobbyL,
  },
  auditorium1: KFAIR,
  auditorium2: MC,

  exhibition1: HallA,
  exhibition2: HallB,

  networking: {
    landscape: NetworkingL,
    portrait: NetworkingL
  },
  gallerium: Gallerium,

  lobbyMusic: LobbyMusic,
}

const layout = {
  auditoriumVid: `
    top: 12%;
    bottom: 37%;
    left: 13%;
    right: 35.9%;
  `,
}

const lobbyEntries = [
  {
    id: 'auditorium',
    destination: routes.auditorium1,
    css: `
      top: 53%;
      left: 38%;
      right: 50%;
      bottom: 39%;
    `,
  },
  {
    id: 'exhibition',
    destination: routes.exhibition1,
    css: `
      top: 51%;
      left: 76.3%;
      right: 7%;
      bottom: 40%;
    `,
  },
  {
    id: 'networking',
    destination: routes.networking,
    css: `
      top: 62.3%;
      left: 60.8%;
      right: 27%;
      bottom: 28.5%;
    `,
  },
  // {
  //   id: 'gallerium',
  //   destination: routes.gallerium,
  //   css: `
  //     top: 42%;
  //     left: 74%;
  //     right: 16%;
  //     bottom: 47.2%;
  //   `,
  // },
  {
    id: 'helpdesk',
    destination: 0,
    css: `
      top: 80.5%;
      left: 7.5%;
      right: 90%;
      bottom: 24.5%;
    `,
  },
]

export default {
  assets,
  layout,
  lobbyEntries,

  eventName: 'Aavii Virtual Event',
  googleAnalyticsID: '',

  ...config,
}
