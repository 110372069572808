import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { SIZES, COLORS } from '../../styles/theme'

import Axios from 'axios'
import config from '../../config'
import routes from '../../routes'
import { toast } from 'react-toastify'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.entrance.landscape});
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 6vw;
  padding-bottom: 0;

  .reset-form {
    position: fixed;
    bottom: 0;
    right: 5vw;
    padding: 6vw;
    padding-bottom: 0;
    max-height: 100%;
    background-color: ${COLORS.primaryTrans};

    header {
      text-align: center;
      h3 {
        font-weight: 600;
      }
    }

    form {
      background: white;
      padding: 30px 10px;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      margin: auto;
      margin-top: 30px;
      max-width: 400px;
      .form-input {
        display: block;
        letter-spacing: 1px;
        width: 80%;
        margin: auto;
        background: ${COLORS.lightGray};
        border: none;
        padding: 12px 32px;
        border-radius: 25px;
        outline: none;
        margin-top: 20px;
        &::placeholder {
          color: ${COLORS.lightGray1};
        }
      }
    }
  }
  ${SIZES.mobile} {
    position: static;
    padding: 20% 10px;
    min-height: 100vh;
    max-height: auto;

    form {
      border-radius: 10px;
    }
  }
  a,
  button.submit-btn {
    background: ${COLORS.primary};
    background: linear-gradient(
      90deg,
      ${COLORS.primary} 0%,
      ${COLORS.primaryComp} 100%
    );
    width: 80%;
    color: white;
    font-weight: 500;
    font-style: italic;
    border-radius: 56px;
    display: flex;
    justify-content: space-between;
    padding: 8px 30px;
    margin: auto;
    margin-top: 3em;
    margin-bottom: 1em;
    font-size: 1em;
    outline: none;
    box-shadow: none;

    &:disabled {
      cursor: not-allowed;
    }

    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function SetPasswordFormPage() {
  const location = useLocation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    Axios.post(`${config.apiUrl}/api/v1/create_password`, {
      password
    }, {
      headers: {
        Authorization: `Bearer ${queryString.parse(location.search).token}`,
      },
    })
      .then(() => {
        setLoading(false)
        toast('Your password is successfully created!')
        history.push(routes.root)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    password.length &&
    passwordConfirmation.length &&
    password === passwordConfirmation
  )

  return (
    <Page>
      <div className="reset-form">
        <header>
          <h3>Create Password</h3>
        </header>
        <form onSubmit={handleSubmit}>
          <input
            className="form-input"
            required
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            className="form-input"
            required
            type="password"
            placeholder="Password Confirmation"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <div>
            {hasEmptyField && (
              <p className="text-danger text-center">
                <em>
                  <small>Please make sure both password matches</small>
                </em>
              </p>
            )}
            <Button
              type="submit"
              className="submit-btn"
              variant="default"
              disabled={hasEmptyField || loading}
              onSubmit={handleSubmit}
            >
              <div>{loading ? 'Loading...' : 'CREATE PASSWORD'}</div>
              <div className="arrow">⇀</div>
            </Button>
          </div>
        </form>
      </div>
    </Page>
  )
}
