import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'

import logo from '../../images/event-logo.png';
import kfair20Tagline from '../../images/mobile/kfair20-tagline.png';
import kwspWhite from '../../images/mobile/kwsp-white.png';
import emc from '../../images/mobile/emc.png';

const MobileLanding = (props) => {

  return (
    <Page>

      <img className='kfair' src={logo}/>
      {/* <img className='kwsp' src={kwspWhite}/>
      <img className='emc' src={emc}/> */}

      <Link className='login-button' to='/login'>Enter</Link>
    </Page>
  );
}

const Page = styled.div`


  .kfair {
    width: 50%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
  }

  .emc {
    width: 140px;
    position: absolute;
    top: 45%;
    left: 73%;
    transform: translateX(-50%);
  }

  .kwsp {
    width: 130px;
    position: absolute;
    /*top: 30%;*/
    /*top: 27%;*/
    top: calc(45% - 120px);
    left: 50%;
    transform: translateX(-50%);
  }
  

  .login-button {
    width: 280px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background: #6d020a;*/
    background: #fab700;
    /* background-image: linear-gradient(rgba(113, 3, 11, 1), rgba(39, 13, 72, 1)); */
    border: none;
    border-radius: 30px;
    /*font-size: 14px;*/
    color: white;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 540px) {

    .kfair {
      width: 200px;
      position: absolute;
      top: 45%;
      left: 27%;
      transform: translateX(-50%);
    }

    .emc {
      width: 210px;
      position: absolute;
      top: 45%;
      left: 73%;
      transform: translateX(-50%);
    }

    .kwsp {
      width: 200px;
      position: absolute;
      /*top: 30%;*/
      /*top: 27%;*/
      top: calc(45% - 180px);
      left: 50%;
      transform: translateX(-50%);
    }

  }
`;

export default MobileLanding;