import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Redirect, Link } from 'react-router-dom'
import Axios from 'axios'
import { toast } from 'react-toastify'

import routes from '../../routes'
import config from '../../config'

import Logo2 from './components/Logo2.js'
import Message from './components/Message.js'
import FormWrapper from './components/FormWrapper.js'

const MobileForgotPassword = () => {
  const { isAuthenticated } = useSelector((state) => state.auth)
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInput = (e) => {
    setEmail(e.target.value);
  }

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    Axios.get(`${config.apiUrl}/api/v1/forget_password`, {
      params: {
        email,
      },
    })
      .then(function (response) {
        setLoading(false)
        if (response.data.exist) {
          toast(
            'We have sent you a password recovery email, please check your mailbox.'
          )
          setEmail('')
        } else {
          toast('This email does not seem to be registered to our system.')
        }
      })
      .catch(function (error) {
        setLoading(false)
        toast(error.response.data.message)
      })
  }

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  }

  return (
    <div>
      <Logo2 />
      <ContentDiv>
        <p className="title">Forgot Password</p>
        <p className="subtitle">Enter Email to reset Password</p>
        <form onSubmit={handleSubmit}>
          <input type="email" value={email} onChange={handleInput} placeholder="Email" />
          <button type="submit" className="button" disabled={email.length < 4 || loading}>
            {
              loading ?
              'Submitting' :
              'Submit'
            }
          </button>
        </form>
        <Link to={routes.login} className="form-link">
          Cancel
        </Link>
      </ContentDiv>
    </div>
  )
}

const ContentDiv = styled.div`
  position: absolute;
  /*top: 18%;*/
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 200px;
  /*background: orange;*/
  /*opacity: 0.5;*/

  .title {
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    color: #ffad2c;
    margin-bottom: 10px;
    text-align: center;
  }

  .subtitle {
    font-size: 14px;
    color: white;
    text-align: center;
    margin-bottom: 50px;
  }

  form {
    width: 280px;
    margin: auto;
  }

  input {
    width: 100%;
    background: white;
    padding: 15px 30px 15px 30px;
    border: none;
    border-radius: 100px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .button {
    width: 100%;
    background: #fab700;
    padding: 15px 30px 15px 30px;
    border: none;
    border-radius: 100px;
    color: white;
    font-style: italic;
    /*display: flex;*/
    /*justify-content: space-between;*/
  }

  .button:hover {
    cursor: pointer;
  }

  .button:disabled {
    opacity: 0.5;
  }

  .form-link {
    display: block;
    text-align: center;
    color: white;
    font-style: italic;
    font-size: 12px;
    margin-top: 20px;
  }
`

export default MobileForgotPassword
