import firebase from 'firebase/app'
import 'firebase/database'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'aavii-demo.firebaseapp.com',
  projectId: 'aavii-demo',
  storageBucket: 'aavii-demo.appspot.com',
  messagingSenderId: '902636924743',
  appId: '1:902636924743:web:677329a5ca918841486662',
  // measurementId: 'G-MYCN9XLCDX',
}
firebase.initializeApp(firebaseConfig)
const db = firebase.database()
const ChatDb = db.ref('chat')
const AuditoriumDb = db.ref('chatAuditorium')
const ChatKfairDb = db.ref('chatKfair')
const ChatMcDb = db.ref('chatMc')
const NoticeDb = db.ref('notice')
const NotificationDb = db.ref('pushNotifications')
const TestChatDb = db.ref('testChat')
const AllowedJwtDb = db.ref('allowedJwt')
const CheckInDb = db.ref('checkin')
const ShowSession = db.ref('showSession')

export { db, ChatDb, AuditoriumDb, ChatKfairDb, ChatMcDb, NoticeDb, NotificationDb, TestChatDb, AllowedJwtDb, CheckInDb, ShowSession }
