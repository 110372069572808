import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import pinkPin from '../images/pin.png'
import infoIcon from '../images/info.png'
import { COLORS } from '../styles/theme'

const PinWrapper = styled.div`
  ${(props) => props.css}
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    /* box-shadow: 0px 0px 30px 15px ${COLORS.accentLight}; */

    .pins {
      animation-name: bounce;
      animation-duration: 1s;
      animation-fill-mode: both;

      .pin-text {
        display: block;
      }
    }
  }

  .pins {
    margin-top: -55px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background-image: ${(props) => (props.info ? `url(${infoIcon})` : `url(${pinkPin})`)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: heartbeat 7s infinite;

    .pin-text {
      position: absolute;
      z-index: 20;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      white-space: nowrap;
      display: none;

      h5 {
        color: white;
        text-shadow: 1px 1px 1px #000;
      }
    }
  }
`

export default function LobbyEntry({
  id,
  showEntryOverlay,
  overlayText,
  setOverlayTitle,
  pinText,
  destination,
  css,
  showHelpDesk,
}) {
  const history = useHistory()

  const handleClick = () => {
    if (Array.isArray(destination)) {
      showEntryOverlay(destination)
      if (overlayText) {
        setOverlayTitle(overlayText)
      }
    } else if (typeof destination === 'string') {
      history.push(destination)
    } else {
      showHelpDesk()
    }
  }

  return (
    <PinWrapper css={css} info={id==="helpdesk"} onClick={handleClick}>
      <div className="pins" >
        <div className="pin-text">
          <h5>{pinText}</h5>
        </div>
      </div>
    </PinWrapper>
  )
}
