import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Axios from 'axios'
import ReactGA from 'react-ga'
import amplitude from 'amplitude-js'
import { Row, Col } from 'react-bootstrap'
import { useList } from 'react-firebase-hooks/database'
import config from '../config'
import { COLORS, SIZES } from '../styles/theme'
import { ChatDb } from '../constants/firebaseConfig'
import LoadIcon from '../components/LoadIcon'
import ChatCard from '../components/Networking/ChatCard'
import UserDirectory from '../components/Networking/UserDirectory'
import ContactCard from '../components/Networking/ContactCard'

const Page = styled.div`
  padding-top: ${SIZES.navHeight};
  height: 100vh;
  background-size: cover;
  background-image: url(${config.assets.networking.landscape});
  background-repeat: no-repeat;
  text-align: center;
  overflow: hidden;

  #content {
    border-radius: 4px;
    position: absolute;
    top: ${SIZES.navHeight};
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 90%;

    .row-wrapper {
      height: 100%;

      [class^='col-'] {
        height: 100%;
      }
    }
  }

  #refresh-btn {
    border: none;
    background: white;
    padding: 5px 10px;

    &:hover {
      border: solid 1px ${COLORS.primary};
    }
  }
  .chat-header {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 32px 24px;
    header {
      padding-bottom: 4px;
      margin-bottom: 8px;
      h6 {
        font-weight: 600;
        text-align: left;
        color: ${COLORS.primary};
      }
      .contact-list {
        align-self: flex-end;
        cursor: pointer;
        margin: 0 8px;
        line-height: 30px;
        vertical-align: middle;
        border: none;
        outline: none;
        background: transparent;
        color: #312782;
      }
    }

    .topics {
      display: flex;
      margin: 8px;

      button {
        border-radius: 50%;
        border: none;
        background: transparent;
      }
      .topic-label {
        display: none;
        font-weight: bold;
      }
      .selected {
        margin-left: 0;
        margin-right: auto;
        .topic-label {
          display: inline-block;
        }
      }
    }
    .divider {
      background: linear-gradient(270deg, #fe1e9a, #254dde);
      height: 2px;
    }
  }
`

export default function NetworkingLoungePage({ currentUser }) {
  const [snapshots, loading, error] = useList(ChatDb)
  const [viewUser, setViewUser] = useState(null)
  const [showDirectory, setShowDirectory] = useState(false)
  const [users, setUsers] = useState([
    {
      id: 1,
      email: "tester@demo.com",
      fullName: "John Smith",
      firstName: "John",
      lastName: "Smith",
      companyName: "ABC Co.",
    },
    {
      id: 2,
      email: "tester2@demo.com",
      fullName: "Jane Doe",
      firstName: "Jane",
      lastName: "Doe",
      companyName: "EFG Co.",
    },
    {
      id: 3,
      email: "tester3@demo.com",
      fullName: "Edward William",
      firstName: "Edward",
      lastName: "William",
      companyName: "XYZ Co.",
    }
  ])

  useEffect(() => {
    document.title = `${config.eventName} | Networking`
  }, [])

  // useEffect(() => {
  //   ReactGA.pageview(`/networking-lounge`)
  //   amplitude.getInstance().logEvent(`Visit Networking Lounge`)
  // }, [currentUser.id])

  // useEffect(() => {
  //   Axios({
  //     url: `${config.apiUrl}/api/v1/users`,
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
  //     },
  //   }).then((result) => {
  //     setUsers(result.data.users)
  //   })
  // }, [])

  const refreshPage = () => {
    window.location.reload()
  }

  const toggleDirectory = () => {
    setShowDirectory(!showDirectory)
  }

  const sendMessage = (text) => {
    const payload = {
      dbUser: currentUser.fullName,
      dbMessage: text,
      dbTimestamp: Date.now(),
      dbUserId: currentUser.id,
    }
    if (currentUser.avatar) {
      payload.dbUserAvatarUrl = currentUser.avatar.url
    }
    ChatDb.push(payload)
  }

  const toggleAvatar = (avatarId) => {
    if (users) {
      let filtered = users.filter((user) => user.id === avatarId)
      setViewUser(filtered[0])
    }
  }
  return (
    <Page>
      <div id="content">
        <Row noGutters className="row-wrapper">
          <Col sm={3} lg={4}>
            <div className="px-3 h-100">
              {viewUser && (
                <ContactCard
                  isSelf={viewUser && viewUser.email === currentUser.email}
                  user={viewUser || currentUser}
                  currentUser={currentUser}
                />
              )}
            </div>
          </Col>
          <Col sm={6} lg={4}>
            {loading ? (
              <LoadIcon />
            ) : error ? (
              <button id="refresh-btn" onClick={refreshPage}>
                Connection failed. Click here to refresh.
              </button>
            ) : (
              <div className="chat-header">
                <header className="d-flex justify-content-between align-items-end">
                  <h6>Networking Lounge</h6>
                  <div>
                    <button
                      className="contact-list d-flex"
                      onClick={toggleDirectory}
                    >
                      <svg width="26" height="26" viewBox="0 0 48 48">
                        <path
                          fill="#312782"
                          d="M23.896,31.209c2.59-1.72,4.302-4.659,4.302-7.994c0-5.29-4.303-9.593-9.593-9.593
                          c-5.29,0-9.593,4.303-9.593,9.593c0,3.335,1.712,6.275,4.302,7.994c-5.86,2.162-10.057,7.787-10.057,14.389h2.558
                          c0-7.053,5.737-12.79,12.79-12.79c7.053,0,12.79,5.737,12.79,12.79h2.558C33.953,38.996,29.756,33.371,23.896,31.209z
                          M18.605,30.249c-3.879,0-7.035-3.156-7.035-7.035s3.156-7.035,7.035-7.035s7.035,3.156,7.035,7.035S22.484,30.249,18.605,30.249z"
                        />
                        <path
                          fill="#312782"
                          d="M36.686,24.814c2.59-1.72,4.302-4.659,4.302-7.994c0-5.29-4.303-9.593-9.593-9.593
                          c-2.563,0-4.888,1.016-6.61,2.659c-0.638,0.608-1.189,1.304-1.641,2.066c0.798,0.323,1.557,0.721,2.261,1.198
                          c0.452-0.736,1.034-1.381,1.717-1.905c1.186-0.912,2.665-1.46,4.274-1.46c3.879,0,7.035,3.156,7.035,7.035
                          c0,3.22-2.177,5.935-5.135,6.767c-0.605,0.17-1.241,0.268-1.9,0.268c-0.226,0-0.449-0.013-0.67-0.034
                          c-0.046,0.921-0.19,1.814-0.431,2.671c0.366-0.032,0.727-0.079,1.101-0.079c0.519,0,1.029,0.04,1.532,0.101
                          c6.332,0.761,11.258,6.156,11.258,12.689h2.558C46.743,32.601,42.546,26.976,36.686,24.814z"
                        />
                      </svg>
                      <span className="mx-1">Who's here</span>
                    </button>
                  </div>
                </header>
                <div className="divider"></div>
                <div className="topics">
                  <span className="font-weight-bold">
                    Say hi and join our discussion here
                  </span>
                  {/* {
                    topics.map(topic=>(
                      <div key={topic} className={`topic ${selectedTopic === topic && "selected"}`} onClick={()=>pickTopic(topic)}>
                        <button>
                          <Avatar name={topic} size="26" round />
                        </button>
                        <span className="topic-label">{topic}</span>
                      </div>
                    ))
                  } */}
                </div>
                <div className="divider"></div>
                <ChatCard
                  conversations={snapshots.slice(
                    Math.max(snapshots.length - 100, 0)
                  )}
                  sendMessage={sendMessage}
                  currentUser={currentUser}
                  toggleAvatar={toggleAvatar}
                />
              </div>
            )}
          </Col>
          <Col sm={3} lg={4}>
            <div className="px-3 h-100">
              {showDirectory && (
                <UserDirectory
                  users={users}
                  setViewUser={setViewUser}
                  toggleDirectory={toggleDirectory}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Page>
  )
}
