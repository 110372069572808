import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import UserSearch from './UserSearch'
import back from '../../images/icons/back.png'
import { COLORS } from '../../styles/theme'

const Directory = styled.div`
  height: 100%;
  
  .directory {
    height: 100%;
    text-align: left;
    /* backdrop-filter: blur(20px); */
    background-color: rgba(255,255,255,0.75);
    padding: 32px 24px;

    .title {
      font-weight: bold;
      color: #312782;
      background: transparent;
      border: none;
      display: flex;
      #back-icon {
        display: flex;
        width: 30px;
        flex-direction: column;
        transition: 1s;
        img {
          width: 25px;
        }
        .icon-label {
          text-align: left;
          font-size: 1px;
          opacity: 0;
        }
        &:hover {
          img {
            transform: scale(0.6);
          }
          .icon-label {
            font-size: 8px;
            opacity: 1;
          }
        }
      }
      
      span {
        font-size: 16px;
        font-style: italic;
        margin-left: 1em;
        margin-bottom: 1em;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      color: ${COLORS.primary};
      font-size: 0.9em;
      height: calc(100% - 110px);
      overflow-y: scroll;
      li {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 1em 1.2em;
        cursor: pointer;
        &:hover {
          background: rgba(255, 255, 255, 0.9);
        }
        .profile-image {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .info {
          margin-left: 1em;
          .name {
            font-weight: bold;
            text-transform: capitalize;
          }
        }
      }
    }
  }
`

const UserDirectory = React.memo(({ toggleDirectory, setViewUser, users = [] }) => {
  const [search, setSearch] = useState('')

  return (
    <Directory>
      <div className="directory">
        <button onClick={toggleDirectory} className="title">
          <div id="back-icon">
            <img src={back} alt="back-icon" />
            <small className="icon-label">Back</small>
          </div>
          <span>Find Who's Here</span>
        </button>
        <UserSearch search={search} setSearch={setSearch} />
        <ul>
          {useMemo(() => {
            const getUsers = () => {
              const searchTerm = search.toLowerCase()
              if (search.length) {
                return users.filter(
                  (u) =>
                    // u.companyName.toLowerCase().includes(searchTerm) ||
                    u.fullName.toLowerCase().includes(searchTerm)
                )
              } else {
                return users
              }
            }

            return getUsers().map((user) => {
              const { email, fullName, avatar } = user
              return (
                <li key={email} onClick={() => setViewUser(user)}>
                  <div className="profile-image">
                    {
                      avatar ?
                      <img src={avatar.url} width="36" className="avatar" alt="profile-image" />
                      :
                      <Avatar round name={fullName} size="36" />
                    }
                  </div>
                  <div className="info">
                    <div className="name">{fullName?.toLowerCase()}</div>
                    {/* <div className="company">{companyName}</div> */}
                  </div>
                </li>
              )
            })
          }, [search, setViewUser, users])}
        </ul>
      </div>
    </Directory>
  )
})

export default UserDirectory
