import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import amplitude from 'amplitude-js';
import Axios from 'axios'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import { useDispatch } from 'react-redux'
import { showGlobalModal, muteMusic, unmuteMusic } from '../ducks/layout'
import config from '../config'
import { COLORS, SIZES } from '../styles/theme'
import attendance from '../images/icons/task.png'
import ChatSection from '../components/ChatSection'
import SideAgenda from '../components/SideAgenda'
import { Redirect } from 'react-router-dom'
import routes from '../routes'
import { AuditoriumDb, CheckInDb, ShowSession } from '../constants/firebaseConfig'
import { useList, useObject } from 'react-firebase-hooks/database'
import { toast } from 'react-toastify'

const Page = styled.div`
  overflow: hidden;
  .content {
    ${config.layout.auditoriumVid}
    position: absolute;
    background: black;

    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .chat {
    max-width: 350px;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    top: 0;
    left: 67%;
    bottom: 0;
    right: 5%;
    z-index: 30;
  }
  .attendance {
    position: fixed;
    top: 6.5%;
    left: 2%;
    display: flex;
    animation: heartbeat 5s infinite;

    .action-label {
      color: white;
      font-weight: 500;
      margin: 0 16px;
      font-style: italic;
      align-self: center;
    }
    button {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0 auto;
      img {
        border-radius: 50%;
      }
    }
    &:hover {
      .action-label,
      img {
        transition: 500ms;
        transform: scale(1.2);
      }
      img {
        box-shadow: 0px 0px 30px 6px #ffad2c;
      }
    }
  }
  .actions {
    position: fixed;
    top: 70%;
    bottom: 25%;
    left: 13%;
    right: 35.9%;
    display: flex;
    justify-content: space-between;
    button {
      /* width: 145px; */
      background-image: linear-gradient(#492777, #c4000c);
      border: 2px solid #e20613;
      border-radius: 16px;
      color: white;
      font-size: 12px;
      &.active:hover {
        box-shadow: 0px 10px 10px -10px #e20613;
        animation-name: bounce;
        animation-duration: 1s;
        animation-fill-mode: both;
      }
      &:disabled {
        cursor: not-allowed;
        background: none;
        background-color: ${COLORS.lightGray1};
        color: black;
        border: 2px solid white;
      }
    }
  }
`

export default function AuditoriumPage({
  liveUrl,
  hallBg,
  hallNumber,
  currentUser,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [sidebar, setSidebar] = useState(false)
  const [checkin, setCheckin] = useState([])
  // const [snapshot, sessionLoading, sessionError] = useObject(ShowSession)
  // const [snapshots, loading, error] = useList(CheckInDb)
  // const [agendaList, setAgendaList] = useState([])

  const dispatch = useDispatch()
  const groudId = [ [1,2,3,4], [5]]

  const [ agendaList, setAgendaList ] = useState([
    {
      id: 1,
      start: "11:00 AM",
      end: "12:15 PM",
      desc: "Stay Safe Online: Take Control of Your Digital Life",
      attendance: false,
      url: 505055532,
    },
    {
      id: 2,
      start: "02:15 PM",
      end: "02:30 PM",
      desc: "Conquering 2020 in the New Normal",
      attendance: false,
      url: 505055532,
    },
    {
      id: 3,
      start: "02:45 PM",
      end: "03:30 PM",
      desc: "Pandemic Crisis: Global Economic Recovery",
      attendance: false,
      url: 505055532,
    },
    {
      id: 4,
      start: "03:45 PM",
      end: "04:30 PM",
      desc: "Dealing with Change: Turning the Mind Towards Acceptance",
      attendance: false,
      url: 505055532,
    },
    {
      id: 5,
      start: "04:45 PM",
      end: "05:30 PM",
      desc: `Changing the World of "Work-Life Harmony"`,
      attendance: false,
      url: 505055532,
    },
  ])
  // useEffect(() => {
  //   if (snapshot) {
  //     let filtered = snapshots.filter(item => groudId[parseInt(snapshot.val())].includes(item.val().id))
  //     const checkInList = []
  //     for (let i = 0; i < filtered.length;i++){
  //       let selected = agendaList.filter(item=>item.id==filtered[i].val().id)
  //       checkInList.push({
  //         ...selected[0],
  //         active: filtered[i].val().active,
  //       })
  //     }
  //     setCheckin(checkInList)
  //     // Axios({
  //     //   url: `${config.apiUrl}/api/v1/hall_sessions?hall_id=1`,
  //     //   headers: {
  //     //     Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
  //     //   },
  //     // }).then((result) => {
  //     //   let withVideo = result.data.sessions.map((item) => {
  //     //     return {
  //     //       ...item,
  //     //       url: videoList[item.id],
  //     //     }
  //     //   })
  //     //   setAgendaList(withVideo)
  //     //   const checkInList = []
  //     //   for (let i = 0; i < filtered.length;i++){
  //     //     let selected = result.data.sessions.filter(item=>item.id==filtered[i].val().id)
  //     //     checkInList.push({
  //     //       ...selected[0],
  //     //       active: filtered[i].val().active,
  //     //     })
  //     //   }
  //     //   console.log(checkInList)
  //     //   setCheckin(checkInList)
  //     // })
  //   }
  // }, [snapshots, sessionLoading, snapshot])
  const videoList = {
    1: '505055532',
    2: '505055532',
    3: '505055532',
    4: '505055532',
    5: '505055532',
  }

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  useEffect(() => {
    document.title = `${config.eventName} | Conference`
  }, [])

  useEffect(() => {
    amplitude
      .getInstance()
      .logEvent(`Visit ${hallNumber === 1 ? 'KFAIR20' : 'MC20'} Auditorium`)
  }, [currentUser.id, hallNumber])

  const displaySpeaker = () => {
    amplitude.getInstance().logEvent('Click MC20 speaker modal')
    dispatch(showGlobalModal('speaker'))
  }
  const displayAgenda = () => {
    amplitude.getInstance().logEvent('Click MC20 agenda modal')
    dispatch(showGlobalModal('agenda'))
  }

  const toggleSidebar = () => {
    if (sidebar === false)
      amplitude
        .getInstance()
        .logEvent(`Click ${hallNumber === 1 ? 'KFAIR20' : 'MC20'} checkin list`)
    setSidebar(!sidebar)
  }

  const checkinSession = (session) => {
    if (session.active == 1){
      const newAgendaList = agendaList.map((item, index) => ({
        ...item,
        attendance: item.id === session.id ? true : item.attendance,
      }))
      setAgendaList(newAgendaList)
      const newCheckinList = checkin.map((item, index) => ({
        ...item,
        attendance: item.id === session.id ? true : item.attendance,
      }))
      setCheckin(newCheckinList)
      // setIsLoading(true)
      // Axios({
      //   method: 'post',
      //   url: `${config.apiUrl}/api/v1/hall_sessions/${session.id}/check_in`,
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      //   },
      // })
      // .then((response) => {
      //   setIsLoading(false)
      //   toast(`Check-in successful.`)
      //   const newAgendaList = agendaList.map((item, index) => ({
      //     ...item,
      //     attendance: item.id === session.id ? true : item.attendance,
      //   }))
      //   setAgendaList(newAgendaList)
      //   const newCheckinList = checkin.map((item, index) => ({
      //     ...item,
      //     attendance: item.id === session.id ? true : item.attendance,
      //   }))
      //   setCheckin(newCheckinList)
      // })
      // .catch((err) => {
      //   setIsLoading(false)
      //   if (
      //     err &&
      //     err.response &&
      //     err.response.data &&
      //     err.response.data.error
      //   ) {
      //     toast(err.response.data.error)
      //   } else {
      //     toast('Check-in failed. Something went wrong.')
      //   }
      // })
    }
  }
  if (currentUser) {
    // if (hallNumber === 2 && currentUser?.role !== 'manager') {
    //   return <Redirect to={routes.lobby} />
    // }
    return (
      <Page>
        {/* <SideAgenda
          open={sidebar}
          toggleSidebar={toggleSidebar}
          agendaList={agendaList}
          setAgendaList={setAgendaList}
          isLoading={isLoading}
          takeAttendance={checkinSession}
          hallNumber={hallNumber}
          currentUser={currentUser}
        /> */}
        <PageWithBg bgImg={hallBg}>
          <div className="content">
            {isLoading && <LoadIcon />}
            <iframe
              title="Live Broadcast"
              src={liveUrl}
              allow="autoplay; fullscreen"
              allowFullScreen
              frameBorder="0"
              onLoad={() => {
                setIsLoading(false)
              }}
            />
          </div>
          <div className="chat h-100">
            {/* <iframe 
              title="crowdpurr" 
              src={`https://www.crowd.live/BZ9H2`} 
              height="100%" 
              width="100%" 
              frameBorder="0" 
              style={{minHeight: '560px'}}
            ></iframe> */}
            <ChatSection
              currentUser={currentUser}
              firebaseDb={AuditoriumDb}
              hallName={hallNumber === 1 ? 'KFAIR20' : 'MC20'}
            />
          </div>
          {hallNumber === 1 ? (
            <>
              {/* <div className="attendance">
                <button onClick={toggleSidebar}>
                  <img src={attendance} width="60" alt="attendance" />
                </button>
                <small className="action-label">
                  Playback
                </small>
              </div> */}
              {/* <div className="actions">
                {
                  checkin.map(item=>{
                    return (
                      <button 
                        key={`session-${item.id}`}
                        className={item.active==1 && 'active'} 
                        onClick={()=>checkinSession(item)} 
                        disabled={item.active==0 || isLoading || item.attendance}
                        >
                          {
                            item.attendance ?
                            "Checked"
                            :
                            `${item.start}-${item.end}`
                          }
                      </button>
                    )
                  })
                }
              </div> */}
            </>
          ) : (
            <>
              {/* <div className="attendance">
                <button onClick={toggleSidebar}>
                  <img src={attendance} width="60" alt="attendance" />
                </button>
                <small className="action-label">Check-in</small>
              </div> */}
              <div className="actions">
                <button onClick={displayAgenda}>Agenda</button>
                <button onClick={displaySpeaker}>Speakers</button>
              </div>
            </>
          )}
        </PageWithBg>
      </Page>
    )
  }
  // return <LoadIcon />
}
